import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const ProtectedAdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            localStorage.getItem('user_role_id') == '4' ? (
                <Component {...props} />
            ) :
                <Redirect to="/dashboard" /> 
        )}
    />
);

export default ProtectedAdminRoute;