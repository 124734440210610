import React from 'react';
import { Col, Card, CardBody, CardText, Container } from 'reactstrap';
import envirment from './base_url';
import '../assets/css/customstyle.css';

const Queries = () => {
  const initialState = {
    details: [],
    userType: localStorage.getItem('user_role_id'),
    waitstatus: 'Please Wailt....',
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'setDetails':
        return { ...state, details: action.payload };
      case 'setWaitStatus':
        return { ...state, waitstatus: action.payload };
    }
  }, initialState);

  React.useEffect(() => {
    var url = 'contact_show.php';

    fetch(envirment.proxyUrl + envirment.baseUrl + url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.ok) {
          if (res.length == 0 || res.length == undefined) {
            dispatch({ type: 'setWaitStatus', payload: 'No Querries' });
          }
          return res.json();
        }
      })
      .then(res => {
        dispatch({ type: 'setDetails', payload: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);

  return (
    <div className="dsahboard-page">
      <Container>
        <p className="title">Queries</p>
        {state.details.map((item, index) => (
          <Col key={index} sm={12}>
            <Card inverse className={`border text-dark`}>
              <CardBody className="d-flex justify-content-between flex-column card-material">
                <CardText>
                  <label>Name:</label>
                  <div className="address-records">{item.name}</div>
                </CardText>
                <CardText>
                  <label>Email:</label>
                  <div className="address-records">{item.email}</div>
                </CardText>
                <CardText>
                  <label>Issue:</label>
                  <div className="address-records">{item.issue}</div>
                </CardText>
                <CardText>
                  <label>Description:</label>
                  <div className="address-records">{item.description}</div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Container>
    </div>
  );
};

export default Queries;
