import React from "react";
import Page from "components/Page";
import { Card, CardBody, Button, Input, Table } from "reactstrap";
import envirment from "./base_url";
import "../assets/css/mobileVerify.css";
import { Redirect } from "react-router";

const MobileVerification = () => {
  // const obj = localStorage.getItem("mobile_number")

  // if( obj == null ) {
  //   window.location="/"
  // }

  const initialState = {
    userType: localStorage.getItem("user_role_id"),
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    error: "",
  };

  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "otp1":
        return { ...state, otp1: action.payload };
      case "otp2":
        return { ...state, otp2: action.payload };
      case "otp3":
        return { ...state, otp3: action.payload };
      case "otp4":
        return { ...state, otp4: action.payload };
      case "otp5":
        return { ...state, otp5: action.payload };
      case "otp6":
        return { ...state, otp6: action.payload };
      case "setError":
        return { ...state, error: action.payload };
    }
  }, initialState);

  const handleChangeOtp = (e) => {
    if (Number.isInteger(Number(e.target.value))) {
      if (e.target.value.length <= 1) {
        dispatch({ type: e.target.name, payload: e.target.value });
        state[e.target.name] = e.target.value;
      } else {
        var name = e.target.value.slice(0, 1);
        dispatch({ type: e.target.name, payload: name });
      }
    }
    console.log("dd", e.target.value.length);
  };

  const MobileOtpSubmit = () => {
    var otp =
      state.otp1 +
      "" +
      state.otp2 +
      "" +
      state.otp3 +
      "" +
      state.otp4 +
      "" +
      state.otp5 +
      "" +
      state.otp6;

    var valid_status = true;

    if (otp) {
      valid_status = true;
    } else {
      valid_status = false;
    }

    if (valid_status) {
      fetch(envirment.proxyUrl + envirment.baseUrl + "mobile_verify.php", {
        method: "POST",
        body: JSON.stringify({
          mobile: localStorage.getItem("mobile_number"),
          otp: otp,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          console.log("test", res.success);
          if (res.success == 1) {
            localStorage.removeItem("mobile_number");
            return <Redirect to="/login" />;
          } else {
            dispatch({ type: "setError", payload: "Invalid OTP" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <Page>
      <div className="verify-mobile">
        <Card>
          <CardBody>
            <h4 style={{ textAlign: "center" }}>Enter OTP</h4>
            <Table>
              <tr>
                <td>
                  <Input
                    type="text"
                    value={state.otp1}
                    name="otp1"
                    onChange={handleChangeOtp}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={state.otp2}
                    name="otp2"
                    onChange={handleChangeOtp}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={state.otp3}
                    name="otp3"
                    onChange={handleChangeOtp}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={state.otp4}
                    name="otp4"
                    onChange={handleChangeOtp}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={state.otp5}
                    name="otp5"
                    onChange={handleChangeOtp}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={state.otp6}
                    name="otp6"
                    onChange={handleChangeOtp}
                  />
                </td>
              </tr>
            </Table>
            <div>{state.error}</div>
            <div>
              <Button
                style={{ width: "100%", fontSize: "14px" }}
                onClick={MobileOtpSubmit}
              >
                Submit OTP
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Page>
  );
};
export default MobileVerification;
