import React from "react";

const DonateMoneyVol = () => {
  return (
    <div className="donate-amount-button">
      <a
        href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=HFNKSAHP5FLU6"
        target="_blank"
        className="blue-button"
      >
        Donate money to support the homeless
      </a>
    </div>
  );
};

export default DonateMoneyVol;
