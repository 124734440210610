import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const ProtectedDonorRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (

            localStorage.getItem('user_id') !==null ? 
                localStorage.getItem('user_role_id') == '1' ? (
                <Component {...props} />
            ) :(
                <Redirect to="/dashboard" /> )
                 :
                    (<Redirect to="/" /> )

            
        )}
    />
);

export default ProtectedDonorRoute;