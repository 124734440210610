import React, { Component } from "react";
import { Container } from "reactstrap";
import "../assets/css/customstyle.css";

class Terms extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <Container>
          <div className="text-heading" style={{color: "#6e0001"}}>Terms and Conditions</div>
          <ol className="textssss" style={{ textAlign: "justify"}}>
            <li>
              {" "}
              In order to be able to participate as a volunteer, you must be
              over the age of 18. If not you must be accompanied by at least one
              person over the age of 18 on your volunteer trips. 
            </li>
            <li>License to Use</li>
            Subject to your agreement to and compliance with the terms and
            conditions, we grant you a non-exclusive, non-transferable,
            non-sharable, revocable, limited license to use the app/service
            solely for personal, non-commercial use in accordance with the terms
            of the terms and conditions. You are permitted with a revocable
            license to display and use the app, including images, videos, text,
            data displays, illustrations, and other associated content within
            the app. 
            <li>Prohibited Conduct</li>
            You may not access or use the Application for any purpose other than
            that for which we make the Application available. The Application
            may not be used in connection with any commercial endeavors except
            those that are specifically endorsed or approved by us.
            <ul>
              As a user of the Application, you agree not to:
              <li>
                Systematically retrieve data or other content from the
                Application to create or compile, directly or indirectly, a
                collection, compilation, database, or directory without written
                permission from us.
              </li>
              <li>
                Make any unauthorized use of the Application, including
                collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited
                email, or creating user accounts by automated means or under
                false pretenses.
              </li>
              <li>
                Use a buying agent or purchasing agent to make purchases on the
                Application.
              </li>
              <li>
                Use the Application to advertise or offer to sell goods and
                services.
              </li>
              <li>
                Circumvent, disable, or otherwise interfere with
                security-related features of the Application, including features
                that prevent or restrict the use or copying of any Content or
                enforce limitations on the use of the Application and/or the
                Content contained therein.
              </li>
              <li>
                Engage in unauthorized framing of or linking to the Application
              </li>
              <li>
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords.
              </li>
              <li>
                Make improper use of our support services or submit false
                reports of abuse or misconduct.
              </li>
              <li>
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.
              </li>
              <li>
                Interfere with, disrupt, or create an undue burden on the
                Application or the networks or services connected to the
                Application
              </li>
              <li>
                Attempt to impersonate another user or person or use the
                username of another user.
              </li>
              <li>Sell or otherwise transfer your profile.</li>
              <li>
                Use any information obtained from the Application in order to
                harass, abuse, or harm another person.
              </li>
              <li>
                Use the Application as part of any effort to compete with us or
                otherwise use the Application and/or the Content for any
                revenue-generating endeavor or commercial enterprise.
              </li>
              <li>
                Decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the
                Application.
              </li>
              <li>
                Attempt to bypass any measures of the Application designed to
                prevent or restrict access to the Application, or any portion of
                the Application.
              </li>
              <li>
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Application to
                you.
              </li>
              <li>
                Delete the copyright or other proprietary rights notice from any
                Content.
              </li>
              <li>
                Copy or adapt the Application's software, including but not
                limited to Flash, PHP, HTML, JavaScript, or other code.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Application or modifies, impairs,
                disrupts, alters, or interferes with the use, features,
                functions, operation, or maintenance of the Application.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”).
              </li>
              <li>
                Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Application, or using or launching any unauthorized script
                or other software.
              </li>
              <li>
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Application.
              </li>
              <li>
                Use the Application in a manner inconsistent with any applicable
                laws or regulations.
              </li>
            </ul>
            <li>Termination</li>
            <ul>
              <li>
                You may stop using the Service at any time by closing your
                Account, or ceasing to use the Service
              </li>
              <li>
                Foodlynk reserves the right in its sole discretion to cease or
                suspend providing all or any part of the Service immediately
                without any notice to you, if: <br />
                (a) you breach, or threaten or intend to breach, these Terms;{" "}
                <br />
                (b) Foodlynk is required to do so under any applicable law, rule
                or regulation, including, without limitation, the CAN-SPAM Act;{" "}
                <br />
                (c) the Service relies on data, services or another business
                relationship between Foodlynk and a third party service
                provider, and such relationship terminates or changes in such a
                way that affects Foodlynk's ability to continue providing the
                Service; <br />
                (d) continuing to provide the Service could create a substantial
                economic burden on Foodlynk as determined by Foodlynk in its
                sole discretion; or <br />
                (e) continuing to provide the Service could create a security
                risk or material technical burden as determined by Foodlynk in
                its sole discretion.
              </li>
              <li>
                Any of your obligations under these Terms which by their nature
                are intended to survive the termination of your use of the
                Service, shall continue to apply to you after you cease to use
                the Service.
              </li>
              <li>
                Foodlynk may notify the relevant law enforcement authorities or
                other third parties, of any illegal or other prohibited conduct
                by you, including, without limitation, your violation of these
                Terms or unauthorized use of the Site or Service.
              </li>
            </ul>
            <li>Notifications</li>
            FoodLynk App works on the basis of geo triaging and enables the
            Donor to indicate availability of food which needs a notification to
            be sent to Volunteer to collect the food. While volunteers can opt
            out of receiving notifications, volunteers will still get
            notifications if they are logged in the app. A Donor will always get
            notification if a volunteer accepts the Donation pick up. Similarly,
            a Recipient will always get a notification if food is being
            delivered. This is to ensure safety of each party and timely
            confirmation.
            <li>Intellectual Property Rights</li>
            Foodlynk respects the rights of intellectual property owners. If you
            believe that any Content infringes your intellectual property rights
            or other rights, see Foodlynk’s copyright policy If Foodlynk is
            notified via contact through email, that any Content infringes a
            copyright, Foodlynk may in its sole discretion remove such Content
            from the Service, or take other steps that Foodlynk deems
            appropriate, without prior notification to the user or other party
            who supplied or posted that Content. If such user or other party
            believes that the Content is not infringing, he or she may in
            certain circumstances submit a counter-notification to Foodlynk with
            a request to restore the removed content, which Foodlynk may or may
            not honor, in Foodlynk’s sole discretion. Furthermore, you
            acknowledge that we retain all our proprietary rights, titles and
            interest in our name, logo and other marks, and any and all other
            related intellectual property rights, including without limitation
            all modifications, enhancements, derivative works, and upgrades
            thereto. You agree to not use any trademark, service mark, business
            name, domain name or social media account name or handle which
            incorporates in whole or in part Foodlynk marks or is similar to any
            of these.
            <li>Disclaimer of Warranty</li>
            You understand and agree that the applications service is provided
            “as is” and “as available,” without express or implied warranty or
            condition of any kind. Foodlynk makes no representations and
            disclaim any warranties or conditions of satisfactory quality,
            merchantability, fitness for a particular purpose, or
            non-infringement. Foodlynk doesn’t warrants that its service is free
            of malware or other harmful components. In addition, Foodlynk makes
            no representation nor does it warrant, endorse, guarantee, or assume
            responsibility for any third party applications (or the content
            thereof), user content, devices or any other product or service
            advertised, promoted or offered by a third party on or through the
            service or any hyperlinked website, or featured in any banner or
            other advertising and Foodlynk is not responsible or liable for any
            transaction between you and third party providers of the foregoing.
            No advice or information whether oral or in writing obtained by you
            from Foodlynk shall create any warranty on behalf of Foodlynk. This
            section applies to the fullest extent permitted by applicable law.
            <li>Right to Update or Modify Terms and Conditions</li>
            Foodlynk may amend the Terms from time to time. Amendments will be
            effective upon Foodlynk's posting of such updated Terms at this
            location or in the amended policies or supplemental terms on the
            applicable Service(s). Your continued access or use of the Services
            after such posting confirms your consent to be bound by the Terms,
            as amended. If Foodlynk changes these Terms after the date you first
            agreed to the Terms (or to any subsequent changes to these Terms),
            you may reject any such change by providing Foodlynk written notice
            of such rejection within 30 days of the date such change became
            effective, as indicated in the "Effective" date above. This written
            notice must be provided by email from the email address associated
            with your Account to: *email account*. In order to be effective, the
            notice must include your full name and clearly indicate your intent
            to reject changes to these Terms. By rejecting changes, you are
            agreeing that you will continue to be bound by the provisions of
            these Terms as of the date you first agreed to the Terms (or to any
            subsequent changes to these Terms).
            <li>Limitation of Liability</li>
            To the maximum extent permitted by law, Foodlynk will not be liable
            for any indirect, incidental, special, consequential, punitive, or
            multiple damages, or any loss of profits or revenues, whether
            incurred directly or indirectly, or any loss of data, use, goodwill,
            or other intangible losses, resulting from: <br />
            (a) your access to or use of or inability to access or use the
            services; <br />
            (b) the conduct or content of other users or third parties on or
            through the services; or <br />
            (c) unauthorized access, use, or alteration of your content, even if
            Foodlynk has been advised of the possibility of such damages.
            <li>Jurisdiction</li>
            You expressly understand and agree to submit to the personal and
            exclusive jurisdiction of the courts of the country, state, province
            or territory determined solely by Foodlynk to resolve any legal
            matter arising from the Terms and Conditions or related to your use
            of the Foodynk app or website. If the court of law having
            jurisdiction rules that any provision of the agreement is invalid,
            then that provision will be removed from the Terms and Conditions
            and the remaining terms and conditions will continue to be valid in
            full force and effect.
            <li>Indemnity</li>
            You agree, to the extent permitted by law, to indemnify, defend, and
            hold harmless Foodlynk from and against any and all complaints,
            charges, claims, damages, losses, costs, liabilities, and expenses
            (including attorneys’ fees) due to, arising out of, or relating in
            any way to: <br />
            (a) your access to or use of the Services; <br />
            (b) your content; and <br />
            (c) your breach of these Terms.
          </ol>
        </Container>
      </div>
    );
  }
}

export default Terms;
