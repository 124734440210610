import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAyfSMrsadBh4GxKtq-g-qiVqXMXpy6R38",
  authDomain: "chating-66524.firebaseapp.com",
  databaseURL: "https://chating-66524.firebaseio.com",
  projectId: "chating-66524",
  storageBucket: "chating-66524.appspot.com",
  messagingSenderId: "940079007220",
  appId: "1:940079007220:web:34f7c731e968e54bfde5a1",
  measurementId: "G-XJ21QJTKEZ"
};
var config = firebase.initializeApp(firebaseConfig);

export default config;