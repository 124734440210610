import logo_t from "assets/img/logo/logo_t.png";
import sidebar_open from "assets/img/sidebar/sidebar_open.png";
import SourceLink from "components/SourceLink";
import React from "react";
import { MdDashboard, MdWidgets } from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import bn from "utils/bemnames";

const sidebarBackground = {
  backgroundImage: `url("${sidebar_open}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const navComponents = [
  // { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
  // {
  //   to: '/button-groups',
  //   name: 'button groups',
  //   exact: false,
  //   Icon: MdGroupWork,
  // },
  // { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
  // { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
  // {
  //   to: '/dropdowns',
  //   name: 'dropdowns',
  //   exact: false,
  //   Icon: MdArrowDropDownCircle,
  // },
  // { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
  // { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
  // { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
  // { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
];

const navContents = [
  // { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  // { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
];

const pageContents = [
  // { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  // {
  //   to: '/login-modal',
  //   name: 'login modal',
  //   exact: false,
  //   Icon: MdViewCarousel,
  // },
];
const navItemsVolunteer = [
  { to: "/dashboard", name: "Dashboard", exact: true, Icon: MdDashboard },
  { to: "/contactus", name: "Contact Us", exact: true, Icon: MdDashboard },
  { to: "/records", name: "Past Deliveries", exact: true, Icon: MdDashboard },
  { to: "/privacy", name: "Privacy Notice", exact: true, Icon: MdDashboard },
  { to: "/terms", name: "Terms & Conditions", exact: true, Icon: MdDashboard },
  { to: "/signout", name: "Signout", exact: true, Icon: MdDashboard },
];
const navItemsVolunteer1 = [
  { to: "/dashboard", name: "Dashboard", exact: true, Icon: MdDashboard },
  { to: "/contactus", name: "Contact Us", exact: true, Icon: MdDashboard },
  { to: "/records", name: "Past Deliveries", exact: true, Icon: MdDashboard },
  { to: "/allrecipientcl", name: "Recepients", exact: true, Icon: MdDashboard },
  { to: "/privacy", name: "Privacy Notice", exact: true, Icon: MdDashboard },
  { to: "/terms", name: "Terms & Conditions", exact: true, Icon: MdDashboard },
  { to: "/signout", name: "Signout", exact: true, Icon: MdDashboard },
];

const navItemsRecepient = [
  { to: "/dashboard", name: "Dashboard", exact: true, Icon: MdDashboard },
  { to: "/contactus", name: "Contact Us", exact: true, Icon: MdDashboard },
  { to: "/records", name: "Past Receipts", exact: true, Icon: MdDashboard },
  { to: "/privacy", name: "Privacy Notice", exact: true, Icon: MdDashboard },
  { to: "/terms", name: "Terms & Conditions", exact: true, Icon: MdDashboard },
  { to: "/signout", name: "Signout", exact: true, Icon: MdDashboard },
  // { to: '/donorrequest', name: 'Food Request', exact: true, Icon: MdDashboard },
  // { to: "/inprocess", name: "Incoming Food", exact: true, Icon: MdDashboard },
  // { to: '/foodlist', name: 'Donor Request', exact: false, Icon: MdWidgets },
];
const navItemsDonor = [
  { to: "/dashboard", name: "Dashboard", exact: true, Icon: MdDashboard },
  { to: "/contactus", name: "Contact Us", exact: true, Icon: MdDashboard },
  { to: "/records", name: "Past Donations", exact: true, Icon: MdDashboard },
  { to: "/privacy", name: "Privacy Notice", exact: true, Icon: MdDashboard },
  { to: "/terms", name: "Terms & Conditions", exact: true, Icon: MdDashboard },
  { to: "/signout", name: "Signout", exact: true, Icon: MdDashboard },
  // { to: "/food", name: "Donate Food", exact: false, Icon: MdWidgets },
  // {
  //   to: "/foodlist",
  //   name: "Accepted Donations",
  //   exact: false,
  //   Icon: MdWidgets,
  // },
];

// const navItemCommon = [
//   {
//     to: "/login",
//     name: "Sign Out",
//     exact: true,
//     Icon: FiLogOut,
//   },
// ];
// const navItemsCommon = [
//   { to: "/privacy", name: "Privacy Policy", exact: true, Icon: MdDashboard },
//   { to: "/terms", name: "Terms & Conditions", exact: true, Icon: MdDashboard },
// ];

const navItemsAdmin = [
  { to: "/HomeDashboard", name: "Dashboard", exact: true, Icon: MdDashboard },
  { to: "/analytics", name: "Analytics", exact: true, Icon: MdDashboard },
  { to: "/queries", name: "Queries", exact: true, Icon: MdDashboard },
  { to: "/bulkupload", name: "Bulk Upload", exact: true, Icon: MdDashboard },
  { to: "/privacy", name: "Privacy Notice", exact: true, Icon: MdDashboard },
  { to: "/terms", name: "Terms & Conditions", exact: true, Icon: MdDashboard },
  { to: "/signout", name: "Signout", exact: true, Icon: MdDashboard },
];

const bem = bn.create("sidebar");

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBackground}>
        <div className={bem.e("content")}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img src={logo_t} width="150" className="pr-2" alt="" />
              {/* <span className="text-white">
                Food Lynk
              </span> */}
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {localStorage.getItem("user_role_id") === "2" &&
            localStorage.getItem("cheapter_lead") !== "1"
              ? navItemsVolunteer.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : ""}
            {localStorage.getItem("user_role_id") === "2" &&
            localStorage.getItem("cheapter_lead") == "1"
              ? navItemsVolunteer1.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : ""}
            {localStorage.getItem("user_role_id") === "1"
              ? navItemsDonor.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : ""}
            {localStorage.getItem("user_role_id") === "3"
              ? navItemsRecepient.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : ""}
            {localStorage.getItem("user_role_id") === "4"
              ? navItemsAdmin.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : ""}

            {/* {navItemsCommon.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <div>
                    <span>{name}</span>
                  </div>
                </BSNavLink>
              </NavItem>
            ))} */}
            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Components</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem> */}
            <Collapse isOpen={this.state.isOpenComponents}>
              {/* {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))} */}
            </Collapse>

            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Contents</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem> */}
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item")}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Pages</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem> */}
            <Collapse isOpen={this.state.isOpenPages}>
              {pageContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item")}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
