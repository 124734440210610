// by sunil

import React from "react";
import GoogleMap from "../components/Map/DonateFoodMap.js";
import "../assets/css/customstyle.css";
import profile_donor from "../assets/img/users/profile_donor.png";
import envirment from "./base_url";
import ModalPage from "./ModalPage";
import { Button, Card, Col, Row } from "reactstrap";

export default class DonateFood extends React.Component {
  state = {
    places: {},
    error: {},
    duration: "",
    distance: "",
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    address: localStorage.getItem("address"),
  };

  componentDidMount() {
    console.log("hello");
    const { from, to } = this.props.match.params;
    const f = from.split(",");
    const t = to.split(",");

    this.setState({
      places: {
        from: { lat: Number(f[0]), lng: Number(f[1]) },
        to: { lat: Number(t[0]), lng: Number(t[1]) },
      },
    });

    this.setState({
      recipientList: ["jaipur", "kota", "jodhpur"],
    });
    this.handleAccept();
  }

  handleChange = (type, value) => {
    switch (type) {
      case "distance":
        this.setState({ distance: value });
        break;
      case "duration":
        this.setState({ duration: value });
        break;
      default:
        return;
    }
  };

  handleAccept = () => {
    const { food_id, from } = this.props.match.params;
    const f = from.split(",");

    const body = {
      food_id: food_id,
      vol_id: localStorage.getItem("user_id"),
      service_area: {
        lat: Number(f[0]),
        lng: Number(f[1]),
      },
    };
    console.log(body);

    // active_receipent_food_request_list
    fetch(
      envirment.proxyUrl + envirment.baseUrl + "active_vol_food_request.php",
      {
        method: "POST",
        body: JSON.stringify({ ...body }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log(body);
  };

  handleReject() {
    alert("Reject");
  }

  render() {
    return (
      <div className="map-bg" style={{ position: "relative" }}>
        <div
          style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <GoogleMap
            height="90vh"
            zoom={15}
            places={this.state.places}
            handleChange={this.handleChange}
          ></GoogleMap>
        </div>
        <Card className="accept-box">
          {/* <div className="d-flex p-2">
                <img src={imgs} width="30" height="30" alt="user" className="img-fluid col-2"  />
                <img
                  width="30"
                  height="30"
                  alt="user"
                  className="img-fluid col-2"
                />
                <h2 className="lead col-5 text-left">Dinnerinn</h2>
                <p className="lead col-5 text-right">10 mins</p>
              </div> */}
          <div className="tracking-profile">
            <div>
              <img src={profile_donor} />
            </div>
            <div>
              <h4>{this.props.match.params.name}</h4>
              <p>{this.state.address}</p>
            </div>
          </div>
          <div className="d-flex py-2 pt-4">
            <p className="lead col-6 text-left">
              <div>Distance</div>
              <div className="distance">{this.state.distance}</div>
            </p>
            <p className="lead col-6 text-right">
              <div>Duration</div>
              <div className="distance">{this.state.duration}</div>
            </p>
          </div>
          <div className="d-flex py-2 pt-4">
            <ModalPage
              handleAccept={this.handleAccept}
              food_id={this.props.match.params.food_id}
            />
            {/* <Button onClick={this.handleReject} className="col-6">
                  Reject
                </Button> */}
          </div>
        </Card>
      </div>
    );
  }
}

// sir you made that

// import React from "react";
// import {DonateFoodMap} from 'components/Map';
// import '../assets/css/customstyle.css';
// // import imgs from 'sunil/asset/bicycle.svg';
// import {
//     Button,
//     Card,
//     Col,
//     Row,
// } from 'reactstrap';

// export default function DonateFood() {
//     const initialState = {
//         places: {},
//         error: {},
//     };

//     const reducer = (state, action) => {
//         switch (action.type) {
//             case 'setPlaces':
//                 return { ...state, places: action.payload };
//             case 'setError':
//                 return { ...state, error: action.payload };
//             default:
//                 return;
//         }
//     };

//     const [state, dispatch] = React.useReducer(reducer, initialState);

//     React.useEffect(() => {
//         dispatch({
//             type: "setPlaces",
//             payload: {
//                 from: { lat: 20.2961, lng: 85.8245 },
//                 to: { lat: 20.29, lng: 85.82 }
//             }
//         })
//     }, [dispatch])

//     const handleAccept = (e) => {
//         console.log(e.target.value);
//         // fetch(
//         //     envirment.proxyUrl+envirment.baseUrl+'active_receipent_food_request_list.php',
//         //     {
//         //       method: 'POST',
//         //       // body: JSON.stringify({ user_id: userid }),
//         //       headers: { 'Content-Type': 'application/json' },
//         //     },
//         //   )
//         //     .then(res => {
//         //       if (res.ok) {
//         //         return res.json();
//         //       }
//         //     })
//         //     .then(res => {
//         //       dispatch({ type: 'setDetails', payload: res });
//         //     })
//         //     .catch(function (error) {
//         //       console.log(error);
//         //     });
//     }
//     React.useEffect(() => {
//         // fetch(
//         //     envirment.proxyUrl+envirment.baseUrl+'active_receipent_food_request_list.php',
//         //     {
//         //       method: 'POST',
//         //       // body: JSON.stringify({ user_id: userid }),
//         //       headers: { 'Content-Type': 'application/json' },
//         //     },
//         //   )
//         //     .then(res => {
//         //       if (res.ok) {
//         //         return res.json();
//         //       }
//         //     })
//         //     .then(res => {
//         //       dispatch({ type: 'setDetails', payload: res });
//         //     })
//         //     .catch(function (error) {
//         //       console.log(error);
//         //     });

//       }, [dispatch]);

//     return <div style={{position:"relative",height:"100vh"}}>
//                 <div style={{position:"absolute",top:0,right:0,left:0,bottom:0,}}>
//                  <DonateFoodMap
//                     height="90vh"
//                     zoom={15}
//                     places={state.places}
//                   />
//               </div>

//         <Row className="drive">
//             <Col md={12} sm={12} xs={12} className="mb-3 p-2 drive_inner">

//                  <Card>
//                     {/* <div className="d-flex p-2"> */}
//                         {/* <img src={imgs} width="30" height="30" alt="user" className="img-fluid col-2"  /> */}
//                         {/* <img width="30" height="30" alt="user" className="img-fluid col-2"  />
//                         <h2 className="lead col-5 text-left">Dinnerinn</h2>
//                         <p className="lead col-5 text-right">10 mins</p>
//                     </div>
//                     <div className="d-flex py-2 pt-4 p-0">
//                         <p className="lead col-6 text-left">distance 2km</p>
//                         <p className="lead col-6 text-right">3 mins</p>
//                     </div> */}
//                      <div className="d-flex py-2 pt-4">
//                          <Button onClick={handleAccept} value="accpet" sm={6} md={6} className="col-md-12">Accept </Button>
//                       {/* <Button onClick={handleAccept} value="reject" className="col-6">Reject </Button> */}
//                     </div>

//                  </Card>

//             </Col>
//          </Row>

//             </div>
// }
