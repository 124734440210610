import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Button,
  CardHeader,
  Input,
  Container,
} from "reactstrap";
import envirment from "./base_url";
import NotificationSystem from "react-notification-system";
import { Link } from "react-router-dom";
import config from "./firebaseConfig";
import "../assets/css/customstyle.css";

const AllRecipientCL = () => {
  const notificationSystem = React.createRef();
  const addNotification = (e) => {};

  const initialState = {
    details: [],
    userType: localStorage.getItem("user_role_id"),
    waitstatus: "Please Wailt....",
    vol_id: "",
    search: "",
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "setDetails":
        return { ...state, details: action.payload };
      case "setWaitStatus":
        return { ...state, waitstatus: action.payload };
      case "setSearch":
        return { ...state, search: action.payload };
    }
  }, initialState);

  React.useEffect(() => {
    var userid = "";
    let body;
    var url = "chepater_rece.php";

    // active_receipent_food_request_list
    fetch(envirment.proxyUrl + envirment.baseUrl + url, {
      method: "POST",
      body: JSON.stringify({ user_id: localStorage.getItem("user_id") }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          if (res.length == 0 || res.length == undefined) {
            dispatch({ type: "setWaitStatus", payload: "No Records" });
          }
          return res.json();
        }
      })
      .then((res) => {
        dispatch({ type: "setDetails", payload: res });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);

  const data = state.de;
  console.log("adf", state.details);
  const filtered = state.details.filter((item) =>
    item.email.includes(state.search)
  );

  const handleSubmit = (e) => {
    const btname = e.target.name;
    const userData = {
      user_id: e.target.id,
      user_status: e.target.value,
    };
    if (e.target.value == 0) {
      document.querySelector('button[name="' + btname + '"]').innerText =
        "Activated";
      document.querySelector('button[name="' + btname + '"]').value = "1";
    } else if (e.target.value == 1) {
      document.querySelector('button[name="' + btname + '"]').innerText =
        "Deactivated";
      document.querySelector('button[name="' + btname + '"]').value = "0";
    }
    fetch(envirment.proxyUrl + envirment.baseUrl + "user_status_change.php", {
      method: "POST",
      body: JSON.stringify(userData),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {})
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="dsahboard-page">
      <Container>
        <p className="title">Recipients</p>

        <Col md={12} sm={12} xs={12} className="mb-3">
          <Card inverse className={`border text-dark volsearch`}>
            <Input
              type="text"
              name="search"
              placeholder="Search"
              value={state.search}
              onChange={(e) => {
                dispatch({ type: "setSearch", payload: e.target.value });
              }}
            />
          </Card>
        </Col>

        {state.details.length > 0 && filtered.length > 0 ? (
          // { state.details.length > 0 &&
          state.details
            .filter((details) => details.email.includes(state.search))
            .map((item, index) => (
              <Col key={index} sm={12}>
                <div>{item.is_new == 1 ? "New" : ""}</div>
                <Card inverse className={`border text-dark`}>
                  <CardBody className="d-flex justify-content-between flex-column card-material">
                    <CardText>
                      <label>Name:</label>
                      <div className="address-records">
                        {item.first_name} {item.last_name}
                      </div>
                    </CardText>
                    <CardText>
                      <label>Email:</label>
                      <div className="address-records">{item.email}</div>
                    </CardText>
                    <CardText>
                      <label>Mobile Number:</label>
                      <div className="address-records">
                        {item.mobile_number}
                      </div>
                    </CardText>
                    <CardText>
                      <label>Address:</label>
                      <div className="address-records">
                        {item.service_area.map((item2) => (
                          <div className="demo">{item2.address}</div>
                        ))}
                      </div>
                    </CardText>
                    {/* <CardText>
                    <label>Password:</label>
                     {item.password}
                  </CardText> */}
                    <CardText>
                      <label>Status:</label>
                      <div className="address-records">
                        {item.status == 1 ? (
                          <Button
                            id={item.id}
                            name={"btn_" + item.id}
                            onClick={handleSubmit}
                            value={item.status}
                          >
                            Acitvated
                          </Button>
                        ) : (
                          ""
                        )}

                        {item.status == 0 ? (
                          <Button
                            id={item.id}
                            name={"btn_" + item.id}
                            onClick={handleSubmit}
                            value={item.status}
                          >
                            Deactivated
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            ))
        ) : (
          <Col sm={12}>
            <Card
              inverse
              style={{ alignItems: "center" }}
              className={`border text-dark`}
            >
              <CardText>{state.waitstatus}</CardText>
            </Card>
          </Col>
        )}
      </Container>
    </div>
  );
};

export default AllRecipientCL;
