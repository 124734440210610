import React, { useState } from "react";
import accepted from "assets/img/footer/accepted.png";
import donate from "assets/img/footer/donate.png";
import home from "assets/img/footer/home.png";
import dashboard from "assets/img/footer/dashboard.png";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const initialState = {countryCheck:"1" };
  // React.useEffect(() => {
    const [state, dispatch] = React.useReducer((state, action) => {
      switch (action.type) {
        case 'setDonateButton':
          return { ...state, countryCheck: action.payload };
       
      }
    }, initialState);
    
    React.useEffect(() => {  
      fetch('https://extreme-ip-lookup.com/json/')
      .then( res => res.json())
      .then(response => {
       if(response.country == "India"){
         console.log("Country is 1111: ", response.country);
         dispatch({
           type: "setDonateButton",
           payload: "0"
         });
       }
       
       console.log("Country is : ", response.country);
     })
     .catch((data, status) => {
       console.log('Request failed:', data);
     });
   
    }, [dispatch]);
    // }); }, [dispatch]);
  return (
    
    <div className="food-1-footer">
      
      <div className="food-2-footer">
        <NavLink
          to={"/HomeDashboard"}
          activeClassName="active"
          className="footer"
        >
          <div className="f-icon">
            <img src={home} />
            Home
          </div>
        </NavLink>
        {localStorage.getItem("user_role_id") == 1 && (
          <NavLink to={"/dashboard"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={dashboard} />
              Dashboard
            </div>
          </NavLink>
        )}
        {/* {localStorage.getItem("user_role_id") == 2 && (
          <NavLink to={"/dashboard"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={dashboard} />
              Dashboard
            </div>
          </NavLink>
        )} */}
        {/* {localStorage.getItem("user_role_id") == 3 && (
          <NavLink to={"/dashboard"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={dashboard} />
              Dashboard
            </div>
          </NavLink>
        )} */}
        {localStorage.getItem("user_role_id") == 1 && (
          <NavLink to={"/food"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Donate
            </div>
          </NavLink>
        )}
        {localStorage.getItem("user_role_id") == 2 && (
          <NavLink to={"/foodlist"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Volunteer Opportunities
            </div>
          </NavLink>
        )}
  {(state.countryCheck == "1" && localStorage.getItem("user_role_id") == 2)? <NavLink to={"/donatevol"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Donate
            </div>
          </NavLink>:""}
        
        {localStorage.getItem("user_role_id") == 2 && (
          <NavLink to={"/onair"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={accepted} />
              Active Requests
            </div>
          </NavLink>
        )}
        

        {localStorage.getItem("user_role_id") == 1 && (
          <NavLink to={"/foodlist"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={accepted} />
              Accepted
            </div>
          </NavLink>
        )}
        {localStorage.getItem("user_role_id") == 3 && (
          <NavLink to={"/inprocess"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Incoming Food
            </div>
          </NavLink>
        )}
        {localStorage.getItem("user_role_id") == 4 && (
          <NavLink to={"/allvolunteers"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Volunteers
            </div>
          </NavLink>
        )}
        {localStorage.getItem("user_role_id") == 4 && (
          <NavLink to={"/allrecepient"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Recipients
            </div>
          </NavLink>
        )}
        {localStorage.getItem("user_role_id") == 4 && (
          <NavLink to={"/alldonor"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Donors
            </div>
          </NavLink>
        )}
        {localStorage.getItem("user_role_id") == 5 && (
          <NavLink to={"/allrecipientcl"} className="footer" activeClassName="active">
            <div className="f-icon">
              <img src={donate} />
              Recipients
            </div>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default Footer;
