import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Button,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import Modal from "pages/Modal";
import envirment from "./base_url";
import ReactStars from "react-rating-stars-component";
import "../assets/css/customstyle.css";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

const RecordPage = () => {
  const initialState = {
    details: [],
    vol_details: [],
    donor_address: "",
    userType: localStorage.getItem("user_role_id"),
    rating: "Rating",
    donorrating: "",
    RecGivingVolRating: "",
    DonToVolReview: "",
    RecToVolReview: "",
    modal1: false,
  };

  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "setDetails":
        return { ...state, details: action.payload };
      case "setVolDetails":
        return { ...state, vol_details: action.payload };
      case "setDonorAddress":
        return { ...state, donor_address: action.payload };
      case "setRating":
        return { ...state, rating: action.payload };
      case "setDonorRating":
        return { ...state, donorrating: action.payload };
      case "setRecGivingVolRating":
        return { ...state, RecGivingVolRating: action.payload };
      case "setDonToVolReview":
        return { ...state, DonToVolReview: action.payload };
      case "setRecToVolReview":
        return { ...state, RecToVolReview: action.payload };
        case "setModalLocation":
          return { ...state, modal1: action.payload };
    }
  }, initialState);

  React.useEffect(() => {
    if (
      localStorage.getItem("service_area") == undefined ||
      localStorage.getItem("service_area") == ""
    ) {
      dispatch({ type: "setModalLocation", payload: true });
    }
    var userid = "";
    var role_id = "";
    let body;
    var url = "pastRecord.php";
    userid = localStorage.getItem("user_id");
    body = {
      user_id: userid,
      user_role_id: localStorage.getItem("user_role_id"),
      don_to_vol_review: state.DonToVolReview,
      rec_to_vol_review: state.RecToVolReview,
      time:Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    fetch(envirment.proxyUrl + envirment.baseUrl + url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch({ type: "setDetails", payload: res });
      })
      .then((res) => {
        if ("status" == 3 && "rating_status" == 1) {
          dispatch({ type: "setRating", payload: "Rating" });
        } else {
          dispatch({ type: "setRating", payload: "" });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);

  const donorratingChanged = (e) => {
    dispatch({ type: "setDonorRating", payload: e });
  };

  const donorratingSubmit = (e) => {
    fetch(
      envirment.proxyUrl + envirment.baseUrl + "donor_to_volunteer_rating.php",
      {
        method: "POST",
        body: JSON.stringify({
          food_id: e.target.id,
          volunteer_rating: state.donorrating,
          don_to_vol_review: state.DonToVolReview,
          time:Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log("Check Past Records", res);
        return <Redirect to="/records" />;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RecGivingVolunteerRatingChanged = (e) => {
    dispatch({ type: "setRecGivingVolRating", payload: e });
  };
  const DonToVolReviewChanged = (e) => {
    dispatch({ type: "setDonToVolReview", payload: e.target.value });
  };
  const RecToVolReviewChanged = (e) => {
    dispatch({ type: "setRecToVolReview", payload: e.target.value });
  };

  const ReceRatingSubmit = (e) => {
    fetch(
      envirment.proxyUrl + envirment.baseUrl + "rece_to_volunteer_rating.php",
      {
        method: "POST",
        body: JSON.stringify({
          food_id: e.target.id,
          volunteer_rating: state.RecGivingVolRating,
          rec_to_vol_review: state.RecToVolReview,
          time:Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        window.location = "/records";
        console.log("<<Check Data>>", res);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    
    <div className="dsahboard-page records-data">

      <div className="donor-name model-closeimg">
          <Modal
            name="location"
            show={state.modal1}
            backdrop="static"
            keyboard={false}
          >
            <ModalHeader></ModalHeader>
            <ModalBody>
              You need to complete profile to be able to use the app.
            </ModalBody>

            <ModalFooter>
              <Link className="btn btn-primary" to="/profile">
                Go To Profile
              </Link>
            </ModalFooter>
          </Modal>
        </div>
      <Container>
        {localStorage.getItem("user_role_id") == 1 ? (
          <CardText className="title">Donations</CardText>
        ) : (
          ""
        )}
        {localStorage.getItem("user_role_id") == 2 ? (
          <CardText className="title">Deliveries</CardText>
        ) : (
          ""
        )}
        {localStorage.getItem("user_role_id") == 3 ? (
          <CardText className="title">Past Receipts</CardText>
        ) : (
          ""
        )}

        {state.details.length > 0
          ? state.details.map((item, index) => (
              <Col key={index} sm={12}>
                <Card inverse className={`border text-dark`}>
                  <CardBody className="d-flex justify-content-between flex-column card-material">
                    <CardText>
                      <label>Food details:</label>
                      <div className="address-records">{item.food_detail}</div>
                    </CardText>
                    <CardText>
                      <label>Time:</label>
                      <div className="address-records">{item.time_food}</div>
                    </CardText>
                    <CardText>
                      <label>Date:</label>
                      <div className="address-records">
                        {new Date(Number(item.date_food)).toLocaleDateString()}
                      </div>
                    </CardText>
                    {localStorage.getItem("user_role_id") == 1 ? (
                      <CardText>
                        <label>Pickup Location:</label>
                        <div className="address-records">
                          {item.donor_current_location.map((item2) => (
                            <div className="demo">{item2.address}</div>
                          ))}
                        </div>
                      </CardText>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("user_role_id") == 1 ? (
                      <CardText>
                        <label>Review:</label>
                        <div className="address-records">
                          {item.status == 3 && item.rating_status == 1 ? (
                            <Input
                              className="review-input"
                              onChange={DonToVolReviewChanged}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </CardText>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("user_role_id") == 1 ? (
                      <CardText>
                        <label>Rating:</label>
                        <div className="address-records">
                          {item.status == 3 && item.rating_status == 1 ? (
                            <ReactStars
                              className="start-custom"
                              count={5}
                              onChange={donorratingChanged}
                              size={25}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          ) : item.rating != null ? (
                            <ReactStars
                              className="start-custom"
                              count={5}
                              value={item.rating}
                              // onChange={donorratingChanged}
                              size={25}
                              edit={false}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </CardText>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("user_role_id") == 2 ? (
                      <CardText>
                        <label>Donor Rating:</label>
                        {item.donor_rating != null ? (
                          <div className="address-records">
                            <ReactStars
                              className="start-custom"
                              count={5}
                              value={item.donor_rating}
                              size={25}
                              edit={false}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </div>
                        ) : (
                          <div className="address-records">
                            <div>No Rating Given</div>
                          </div>
                        )}
                      </CardText>
                    ) : (
                      ""
                    )}
                    {/* {localStorage.getItem("user_role_id") == 2 ? (
                      <CardText>
                        <label>Donor Review:</label>
                        {item.donor_review != null ? (
                          <div className="address-records">
                            {item.donor_review}
                          </div>
                        ) : (
                          <div className="address-records">
                            <div>No Review Given</div>
                          </div>
                        )}
                      </CardText>
                    ) : (
                      ""
                    )} */}
                    {localStorage.getItem("user_role_id") == 2 ? (
                      <CardText>
                        <label>Recipient Rating:</label>
                        {item.rece_rating != null ? (
                          <div className="address-records">
                            <ReactStars
                              className="start-custom"
                              count={5}
                              value={item.rece_rating}
                              // onChange={donorratingChanged}
                              size={25}
                              edit={false}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          </div>
                        ) : (
                          <div className="address-records">
                            <div>No Rating Given</div>
                          </div>
                        )}
                      </CardText>
                    ) : (
                      ""
                    )}

                    {/* {localStorage.getItem("user_role_id") == 2 ? (
                      <CardText>
                        <label>Recipient Review:</label>
                        {item.rece_review != null ? (
                          <div className="address-records">
                            {item.rece_review}
                          </div>
                        ) : (
                          <div className="address-records">
                            <div>No Review Given</div>
                          </div>
                        )}
                      </CardText>
                    ) : (
                      ""
                    )} */}
                    {localStorage.getItem("user_role_id") == 3 ? (
                      <CardText>
                        <label>Review:</label>
                        <div className="address-records">
                          {item.status == 3 && item.rating_status == 1 ? (
                            <Input
                              className="review-input"
                              onChange={RecToVolReviewChanged}
                            />
                          ) : (
                            <div>{item.review}</div>
                          )}
                        </div>
                      </CardText>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("user_role_id") == 3 ? (
                      <CardText>
                        <label>Rating:</label>
                        <div className="address-records">
                          {item.status == 3 && item.rating_status == 1 ? (
                            <ReactStars
                              className="start-custom"
                              count={5}
                              onChange={RecGivingVolunteerRatingChanged}
                              size={25}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          ) : item.rating != null ? (
                            <ReactStars
                              className="start-custom"
                              count={5}
                              value={item.rating}
                              size={25}
                              edit={false}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </CardText>
                    ) : (
                      ""
                    )}

                    {item.status == 3 &&
                    item.rating_status == 1 &&
                    localStorage.getItem("user_role_id") == 1 ? (
                      <Button id={item.id} onClick={donorratingSubmit}>
                        Submit
                      </Button>
                    ) : (
                      ""
                    )}
                    {item.status == 3 &&
                    item.rating_status == 1 &&
                    localStorage.getItem("user_role_id") == 3 ? (
                      <Button id={item.id} onClick={ReceRatingSubmit}>
                        Submit
                      </Button>
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </Col>
            ))
          : ""}
      </Container>
    </div>
  );
};

export default RecordPage;
