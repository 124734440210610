import React, { Component } from "react";
import moment from "moment";
import { ReactiveBase, DateRange } from "@appbaseio/reactivesearch";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import envirment from "../pages/base_url";
import { Multiselect } from "multiselect-react-dropdown";

class Analytics extends Component {
  constructor() {
    super();
    this.state = {
      userType: "",
      selectedType: "",
      details: [],
      selectedId: [],
      userName: [],
      startDate: "",
      endDate: "",
      newData: [],
      error:[],
    };

    this.selectUser = this.selectUser.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.dateQuery = this.dateQuery.bind(this);
    this.selectedType = this.selectedType.bind(this);
    // this.generatePDF = this.generatePDF.bind(this);
  }

  selectUser(e) {
    console.log("USER VALUE", e.target.value);
    this.setState({
      userType: e.target.value,
    });

    if (e.target.value == 1) {
      var url = "all_donor.php";
    } else if (e.target.value == 2) {
      var url = "all_volunteer.php";
    } else if (e.target.value == 3) {
      var url = "all_recepient.php";
    }

    fetch(envirment.proxyUrl + envirment.baseUrl + url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        this.setState({
          details: res,
        });
        let newDetails = [];
        res.map((item, index) => {
          let arr = { name: item.first_name, id: item.id };
          console.log("<<<<arr>>>>>>", arr);
          newDetails.push(arr);
        });
        this.setState({ userName: newDetails });
        console.log("RESPONSE IN DETAILS", res);
        console.log("RESPONSE", res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  dateQuery(value) {
    let query = null;
    if (value) {
      query = [
        {
          range: {
            date_from: {
              gte: moment(value.start).format("YYYYMMDD"),
            },
          },
        },
        this.setState({
          startDate: value.start,
        }),
        {
          range: {
            date_to: {
              lte: moment(value.end).format("YYYYMMDD"),
            },
          },
        },
        this.setState({
          endDate: value.end,
        }),
      ];
    }
    console.log("Dates");
    return query ? { query: { bool: { must: query } } } : null;
  }

  onSelect(selectedItem) {
    this.setState({
      selectedId: selectedItem,
    });
  }

  selectedType(e) {
    this.setState({
      selectedType: e.target.value,
    });
    console.log("CHECKKKKKKKKKKKKKKKKK TYPE", e.target.value);
  }

  onSubmit(e) {
    e.preventDefault();

    let err = {};

    // if (this.state.user_type.length == 0) {
    //   document.querySelector("select[name=user_type]").focus();
    //   err = { ...err, user_type: "Mandatory*" };
    // } 
    // if (this.state.user_id.length == 0) {
    //   document.querySelector("select[name=user_id]").focus();
    //   err = { ...err, user_id: "Mandatory*" };
    // } 
    // this.setState({
    //   error : err
    // })
    
    let arrId = [];
    this.state.selectedId.map((item, index) => {
      arrId.push(item.id);
    });
    console.log("IDSSSSS", arrId);

    fetch(envirment.proxyUrl + envirment.baseUrl + "analytic.php", {
      method: "POST",
      body: JSON.stringify({
        user_type: this.state.userType,
        status: this.state.selectedType,
        user_id: arrId,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        this.setState({
          newData: res,
        });

        console.log("<<<SPLITTED DATA>>>", res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // generatePDF() {
  //   var doc = new jsPDF('p', 'pt');

  //   doc.text(20, 20, 'This is the first title.' , 'asdasdasdasdasdasdsadsdads');

  //   doc.text(20, 100, 'This is the thrid title.');

  //   doc.save('demo.pdf');
  // };

  render() {
    return (
      <div className="dsahboard-page">
        <Container>
          <div className="ana-page">
            <Label for="exampleSelect" className="analytic-lable">
              User Analytics
            </Label>
            <FormGroup>
              <Input
                className="form-ana"
                // className={
                //   "form-ana "+ this.state.error.user_type ? "is-invalid" : ""
                // }
                type="select"
                name="user_type"
                onChange={this.selectUser}
              >
                <option selected disabled>
                  Select type of User *
                </option>
                <option value="1">Donor</option>
                <option value="2">Volunteer</option>
                <option value="3">Recipient</option>
                
              </Input>
              <Input
                className="form-ana"
                type="select"
                name="select"
                onChange={this.selectedType}
              >
                <option selected disabled>
                  Select type of Data *
                </option>
                <option value="">All</option>
                <option value="0">Pending</option>
                <option value="2">Accepted</option>
                <option value="3">Completed</option>
                <option value="4">Cancelled</option>
              </Input>
              <Multiselect
                options={this.state.userName}
                placeholder="Please Enter Name *"
                displayValue="name"
                showCheckbox={true}
                onSelect={this.onSelect}
              />
              <ReactiveBase
                app="airbeds-test-app"
                url="https://a03a1cb71321:75b6603d-9456-4a5a-af6b-a487b309eb61@arc-cluster-appbase-demo-6pjy6z.searchbase.io"
                enableAppbase
                type="listing"
              >
                <DateRange
                  componentId="DateSensor"
                  className="analytic-date"
                  dataField="date_from"
                  customQuery={this.dateQuery}
                  // initialMonth={new Date()}
                />

                <Button onClick={this.onSubmit}>Search</Button>
              </ReactiveBase>
            </FormGroup>
            <div className="ana-dataset">
              {this.state.newData.length > 0 &&
                this.state.newData.map((item, index) => (
                  <div key={index} className="dataset-inner">
                    <Card inverse className={`border text-dark`}>
                      <CardBody className="d-flex justify-content-between flex-column card-material">
                        <CardText>
                          <label>Food Details:</label>
                          <div className="address-records">
                            {item.food_detail}
                          </div>
                        </CardText>
                        <CardText>
                          <label>Date:</label>
                          <div className="address-records">
                            {item.date_food}
                          </div>
                        </CardText>
                        <CardText>
                          <label>Time:</label>
                          <div className="address-records">
                            {item.time_food}
                          </div>
                        </CardText>
                        {/* <CardText>
                    <label>Donor Location:</label>
                    <div className="address-records">
                      {item.donor_current_location}
                    </div>
                  </CardText> */}
                        <CardText>
                          <label>Total Meals:</label>
                          <div className="address-records">
                            {item.total_meal}
                          </div>
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                ))}
            </div>
            {/* <button onClick={this.generatePDF} type="primary">Download PDF</button>  */}
          </div>
        </Container>
      </div>
    );
  }
}

export default Analytics;
