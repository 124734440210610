import React from "react";
import envirment from "./base_url";
import { Card, CardText, Col, Container, Row, Table, Input, Form } from "reactstrap";
import donor from "../assets/img/donor.png";
import volunteer from "../assets/img/volunteer.png";
import shelter from "../assets/img/shelter.png";
import one from "../assets/img/logo/one.png";
import Modal from "./Modal";
import image1 from "../assets/images/image1.png";
import image5 from "../assets/images/image5.png";
import image2 from "../assets/images/image2.png";
import donation from "../assets/images/donation.png";


import im1 from "../assets/landingpage/1.jpg";
import im2 from "../assets/landingpage/2.jpg";
import im3 from "../assets/landingpage/3.jpg";
import im4 from "../assets/landingpage/4.jpg";
import im5 from "../assets/landingpage/5.jpg";
import im6 from "../assets/landingpage/6.jpg";
import im7 from "../assets/landingpage/7.jpg";
import im8 from "../assets/landingpage/8.jpg";

import logo_t from "../assets/images/logo-t.png";
import "../assets/css/style.css";
import { Link, Redirect } from "react-router-dom";

const LandingPage = () => {
  if (localStorage.getItem("user_id") !== null) {
    return <Redirect to="/HomeDashboard" />;
  }

  const initialState = {
    donorDetails: [],
    volDetails: [],
    recDetails: [],
    todayLog: [],
    userType: localStorage.getItem("user_role_id"),
    popup: false,
    name: "",
    email: "",
    issue: "",
    description: "",
    updateProfile: false,
    error: "",
    success_popup: false,
    totalMeals: "",
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "setDetails":
        return { ...state, donorDetails: action.payload };
      case "setVolDetails":
        return { ...state, volDetails: action.payload };
      case "setRecDetails":
        return { ...state, recDetails: action.payload };
      case "setTodayLog":
        return { ...state, todayLog: action.payload };
      case "setContact":
        return { ...state, popup: action.payload };
      case "setSuccess":
        return { ...state, success_popup: action.payload };
      case "setName":
        return { ...state, name: action.payload };
      case "setEmail":
        return { ...state, email: action.payload };
      case "setIssue":
        return { ...state, issue: action.payload };
      case "setDescription":
        return { ...state, description: action.payload };
      case "setUpdateProfile":
        return { ...state, updateProfile: action.payload };
      case "setError":
        return { ...state, error: action.payload };
      case "setTotalMeals":
        return { ...state, totalMeals: action.payload };
    }
  }, initialState);

  React.useEffect(() => {
    var userid = "";
    let body;
    var url = "all_donor.php";
    fetch(envirment.proxyUrl + envirment.baseUrl + url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role_id") == 1 &&
          localStorage.getItem("user_role_id") == 2 &&
          localStorage.getItem("user_role_id") == 3
        ) {
          if ("doner_detail" in res) {
            dispatch({ type: "setDetails", payload: res.doner_detail.length });
          }
        } else {
          dispatch({ type: "setDetails", payload: res });
        }
        console.log(res.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);



  React.useEffect(() => {
    var url3 = "total_meal.php";

    fetch(envirment.proxyUrl + envirment.baseUrl + url3, {
      method: "POST",
      body: JSON.stringify({ donor_id: localStorage.getItem("user_id") }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        dispatch({
          type: "setTotalMeals",
          payload: res.total_meal,
        });
        console.log("CHECK RESPONSES", res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);

  React.useEffect(() => {
    var userid = "";
    let body;
    var url1 = "all_volunteer.php";
    fetch(envirment.proxyUrl + envirment.baseUrl + url1, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role_id") == 1 &&
          localStorage.getItem("user_role_id") == 2 &&
          localStorage.getItem("user_role_id") == 3
        ) {
          if ("doner_detail" in res) {
            dispatch({ type: "setVolDetails", payload: res.doner_detail });
          }
        } else {
          dispatch({ type: "setVolDetails", payload: res });
        }
        console.log(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);


  const modalOpen = () => {
    dispatch({ type: "setContact", payload: true });
    // this.setState({ [e.target.name]: true });
  }
  const modalClose = () => {
    dispatch({ type: "setContact", payload: false });
  }
  const modalClose2 = () => {
    dispatch({ type: "setSuccess", payload: false });
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case "name":
        dispatch({ type: "setName", payload: e.target.value });
        break;
      case "email":
        dispatch({ type: "setEmail", payload: e.target.value });
        break;
      case "issue":
        dispatch({ type: "setIssue", payload: e.target.value });
        break;
      case "description":
        dispatch({ type: "setDescription", payload: e.target.value });
        break;
    }
  };

  const handleSubmit = (e) => {

    e.preventDefault();

    let err = {};
    if (state.name.length === 0) {
      err = { ...err, name: "Name is Mandatory" };
    } else if (
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        state.email
      ) === false
    ) {
      err = { ...err, email: "Email is Mandatory" };
    } else if (state.issue.length === 0) {
      err = { ...err, issue: "Subject is Mandatory" };
    } else if (state.description.length === 0) {
      err = { ...err, issue: "Description is Mandatory" };
    } else {
      err = null;
    }

    if (err) {
      dispatch({ type: "setError", payload: { ...err } });
      console.log(err);
      return;
    } else {
      dispatch({ type: "setError", payload: { ...err } });
    }

    console.log("STATE DATA CHECK", state);

    const contactUsData = {
      name: state.name,
      email: state.email,
      issue: state.issue,
      description: state.description,
    };

    console.log("DATAAAAAAAAA", contactUsData);

    fetch(envirment.proxyUrl + envirment.baseUrl + "contact.php", {
      method: "POST",
      body: JSON.stringify(contactUsData),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "text/plain",
      },
    })
      .then((res) => {
        if (res.ok) {
          console.log("Request", res);
          return res.json();
        }
      })
      .then((res) => {
        dispatch({ type: "setSuccess", payload: true });
        dispatch({ type: "setUpdateProfile", payload: true });
        dispatch({ type: "setContact", payload: false });
      });
    dispatch({ type: "setName", payload: "" });
    dispatch({ type: "setEmail", payload: "" });
    dispatch({ type: "setIssue", payload: "" });
    dispatch({ type: "setDescription", payload: "" });
  };

  React.useEffect(() => {
    var userid = "";
    let body;
    var url2 = "all_recepient.php";
    fetch(envirment.proxyUrl + envirment.baseUrl + url2, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        if (
          localStorage.getItem("user_role_id") == 1 &&
          localStorage.getItem("user_role_id") == 2 &&
          localStorage.getItem("user_role_id") == 3
        ) {
          if ("doner_detail" in res) {
            dispatch({ type: "setRecDetails", payload: res.doner_detail });
          }
        } else {
          dispatch({ type: "setRecDetails", payload: res });
        }
        console.log(res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);

  React.useEffect(() => {
    fetch(envirment.proxyUrl + envirment.baseUrl + "today_log.php", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch({ type: "setTodayLog", payload: res });
        console.log("<<<LOGS>>>", res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dispatch]);

  return (
    <>

      <div className="top-bar">
        <div className="container">

          <div className="row">
            <div className="col-md-6">

            </div>
            <div className="col-md-6">
              <div className="right-header">
                <ul>
                  <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                  <li><a href="#">  <i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                  <li>  <a href="#"> <i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
                  <li> <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="main-header">
          <div className="row">
            <div className="col-md-12">
              <div className="logo">
                <img src={logo_t} className="logo-img" />
              </div>
              <Link to="/login">Log in / Signup</Link>
            </div>

          </div>

        </div>
      </div>






      <section>
        <div className="banner">
          <div className="container">
            <h4 className="Mission">Mission- Create purposeful linkages to reduce food waste and use it to feed the needy.</h4>
            <h3>FoodLynk has empowered over <span>{state.recDetails.length} shelters, {state.donorDetails.length} Businesses  and  {state.volDetails.length} Volunteers</span>.</h3>
            <h3>Number of Meals Put to Good Cause<span> {state.totalMeals}</span>.</h3>
          </div>

        </div>
      </section>
      <section>



        <div className="food_Sec">
          <div className="container-fluid">
            <div className="row">

              <div className="col-md-4">
                <div className="box">
                  <div className="img-sec">
                    <img src={image1} alt="image" />
                  </div>
                  <div className="content">
                    <h5>Foodlynk</h5>
                    <h3>As a Donor</h3>
                    {/* <Link to={{'/register',state: { params: {id1: value1, id2: value2}}}} className="recipient">Register Now</Link> */}
                    <Link className="recipient" to={{
                      pathname: '/register',
                      state: { params: { userType: 1 } }
                    }}> Register Now </Link>
                  </div>
                </div>
              </div>


              <div className="col-md-4">

                <div className="box">
                  <div className="img-sec">
                    <img src={image5} alt="image" />
                  </div>
                  <div className="content">
                    <h5>Foodlynk</h5>
                    <h3>As a Volunteer</h3>
                    <Link className="recipient" to={{
                      pathname: '/register',
                      state: { params: { userType: 2 } }
                    }}> Register Now </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="box">
                  <div className="img-sec">
                    <img src={image2} alt="image" />
                  </div>
                  <div className="content">
                    <h5>Foodlynk</h5>
                    <h3>As a Recipient</h3>
                    <Link className="recipient" to={{
                      pathname: '/register',
                      state: { params: { userType: 3 } }
                    }} > Register Now </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </section>






      <section>
        <div className="container">
          <div className="about_sec about-section">
            <div className="row">
              <div className="col-md-12">
                <h3>About</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">


                <p className="about">
                  More than 700 Million people go to bed hungry each night. On the other hand, one third of food produced for human consumption each year- approx. 1.3 Bn Tons, gets wasted globally. This is more than enough to feed all the 700 Million hungry people in the world - four times over. FoodLynk was started to help serve the needy and manage this food, paradox primarily using technology.
                </p>

              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="about_sec how-section">
            <div className="row">
              <div className="col-md-12">
                <h3>How It Works</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">


                <p className="about">
                  FoodLynk aims at helping the needy get quality meals. We enable circular economy using technology. FoodLynk app helps make the process smooth and efficient. There are three personas; Donor (Eg Restaurants), Volunteer (Eg you and me), and Recipient (Eg homeless shelter, other needy). When food is available at the end of the day, Donor indicates that on the app. All volunteers in that area get notified. All volunteers see distance to collection and delivery point on the map. The first volunteer to accept the task collects and delivers the food to the recipient in that area. This not only helps the needy, but also reduces the food wastage and donor's carbon footprint. FoodLynk can be used anywhere with an established ecosystem of donors, volunteers, and recipients.
                </p>

              </div>

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="about_sec img-section">
            <div className="row">
              <div className="col-md-12">
                <h3>FoodLynk at Work </h3>
              </div>
            </div>
            <div className="image-section">
              <div className="row">
                <div className="col-md-3">
                  <img className="l-img" src={im1} />
                </div>

                <div className="col-md-3">
                  <img className="l-img" src={im2} />
                </div>
                <div className="col-md-3">
                  <img className="l-img" src={im3} />
                </div>
                <div className="col-md-3">
                  <img className="l-img" src={im4} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <img className="l-img" src={im5} />
                </div>
                <div className="col-md-3">
                  <img className="l-img" src={im6} />
                </div>
                <div className="col-md-3">
                  <img className="l-img" src={im7} />
                </div>
                <div className="col-md-3">
                  <img className="l-img" src={im8} />
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

      <section>

        <div className="chapter_sec">

          <div className="container">
            <h4 className="chapter">FoodLynk is currently looking out for Chapter Leads. These are volunteers, who will establish the ecosystem in their own comunity to help the needy and reduce food wastage.<span id="myBtn"><a href="#" onClick={(e) => modalOpen()}> Contact Us </a> </span> for more detail. </h4>

            <div className="">
              <Modal name="popup" show={state.popup}>
                <div className="modal-content contact_form">
                  <span className="close" onClick={(e) => modalClose()}>&times;</span>
                  <h3>Contact Us</h3>
                  <Form onSubmit={handleSubmit} className="form">

                    <span className="name">
                      <input type="text" placeholder="Name*" name="name" required value={state.name}
                        onChange={handleChange} /><invalid-feedback>{state.error.name}</invalid-feedback></span>
                    <span className="name"><input type="email" placeholder="Enter Email*" name="email" required value={state.email}
                      onChange={handleChange} /><invalid-feedback>{state.error.email}</invalid-feedback></span>
                    <span className="name"><input type="text" name="issue" placeholder="Subject*" value={state.issue}
                      onChange={handleChange} /> <invalid-feedback>{state.error.issue}</invalid-feedback></span>
                    <span className="message"><textarea name="description" cols="40" rows="10" placeholder="Message" value={state.description}
                      onChange={handleChange}></textarea><invalid-feedback>{state.error.description}</invalid-feedback></span>

                    <input type="button" onClick={handleSubmit} value="Send Your Message" className="submit" />
                  </Form>
                </div>
              </Modal>
            </div>

            <div className="">
              <Modal name="popup" show={state.success_popup} >
                <div className="modal-content success_popup">
                  <Form onSubmit={handleSubmit} className="form">

                    <h3>Thank you for your message.We will getting in touch soon.</h3>
                    <input type="button" onClick={(e) => modalClose2()} value="Ok" className="submit" />
                  </Form>
                </div>
              </Modal>
            </div>




          </div>
        </div>

      </section>

      <section style={{ display: "none" }}>
        <div className="sec_4">
          <div className="container">
            <div className="activity_sec">
              <h2 className="activity">Activity Logs</h2></div>

            {state.todayLog.map((item, index) =>
              item[1].first_name != null ? (

                <table id="customers">
                  <tr>
                    <th>Activity</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                  <tr>
                    <td scope="row">
                      Donation scheduled
                    </td>
                    <td>{item[1].first_name.toUpperCase()}</td>
                    <td>{item.date_food}</td>
                    <td>{item.time_food}</td>
                  </tr>
                  <tr>
                    <td scope="row">
                      Volunteer collection
                    </td>
                    <td>{item[2].first_name.toUpperCase()}</td>
                    <td>{item.pickup_date}</td>
                    <td>{item.pickup_time}</td>
                  </tr>
                  <tr>
                    <td scope="row">
                      Recieved
                    </td>
                    <td>{item[3].first_name.toUpperCase()}</td>
                    <td>{item.deleivery_date}</td>
                    <td>{item.deleivery_time}</td>
                  </tr>

                </table>) : ("")
            )}
          </div>
        </div>

      </section>
      <section>

      </section>
      <footer>
        <div className="main-footer">
          <div className="container">

            <p>Copyright© <a href="#">FoodLynk</a> 2021.  All right reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
