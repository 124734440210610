import React from "react";
import ReactDOM from "react-dom";
import workerDev from "./workerDev";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
// import { stopReportingRuntimeErrors  } from "react-error-overlay";
if (process.env.NODE_ENV === "development") {
    // stopReportingRuntimeErrors(); // disables error overlays
   //  import('react-error-overlay').then(m => {
   //  	m.stopReportingRuntimeErrors();
  	// })
    
  }

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
