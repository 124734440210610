import Page from 'components/Page';
import React from 'react';
import { GoogleMap, SingleSelectMap } from 'components/Map';
import userImage from '../assets/img/user.jpg';
import { UserCard } from '../components/Card';
import envirment from './base_url';
import fileUpload from 'fuctbase64';
import '../assets/css/customstyle.css';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { stat } from 'fs';

const AdminProfile = () => {

  const notificationSystem = React.createRef();
  const addNotification = e => {
    
  };

  // const profilestyle = {
  //   width: '16%',
  //   height: '12%',
  //   '@media (minWidth: 992px)': {
  //     maxWidth: '100% !important',
  //     height: '100vh !important',
  // 	},
  // 	'@media (minWidth: 767px)': {
  //     maxWidth: '100% !important',
  //     height: '100vh !important',
  //   },
  //   '@media (minWidth: 678px)': {
  //     maxWidth: '100% !important',
  //     height: '100vh !important',
  // 	},
  // };
  const initialState = {
    userType: localStorage.getItem('user_role_id'),
    email: localStorage.getItem('email'),
    user_store_id: localStorage.getItem('user_id'),
    mobileNumber: '',
    uid: '',
    address: '',
    userprofile: userImage,
    userUploadImage: null,
    submitbuttontext: 'Update',
    submitbuttonstyle: null,
    places: [],
    zoom: 15,
    error: {},
  };


  const reducer = (state, action) => {
    switch (action.type) {
      case 'setEmail':
        return { ...state, email: action.payload };
      case 'setMobileNumber':
        return { ...state, mobileNumber: action.payload };
      
      case 'setUid':
        return { ...state, uid: action.payload };
      case 'setLocation':
        return { ...state, location: action.payload };
      case 'setPlaces':
        return { ...state, places: action.payload };
      case 'setZoom':
        return { ...state, zoom: action.payload };
      case 'setError':
        return { ...state, error: action.payload };
      case 'setUserProfile':
        return { ...state, userprofile: action.payload }
      case 'setProfileBase64':
        return { ...state, userUploadImage: action.payload }
      case 'setSubmitButtonText':
        return { ...state, submitbuttontext: action.payload }
      case 'setSubmitButtonStyle':
        return { ...state, submitbuttonstyle: action.payload }

    }
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleChange = e => {

    switch (e.target.name) {
      case 'email':
        dispatch({ type: 'setEmail', payload: e.target.value });
        break;
      case 'mobileNumber':
        if (e.target.value[e.target.value.length - 1] !== '.') {
          if (Number.isInteger(Number(e.target.value))) {
            if (e.target.value.length <= 10) {
              dispatch({
                type: 'setMobileNumber',
                payload: e.target.value.trim(),
              });
            }
          }
        }
        // dispatch({ type: 'setMobileNumber', payload: e.target.value });
        break;
      
    }
  };
  const handleChangeProfile = async (e) => {
    const d = e.target.files[0];
    const h = await fileUpload(e);

    dispatch({ type: 'setUserProfile', payload: URL.createObjectURL(d) });
    dispatch({ type: 'setProfileBase64', payload: h.base64 });


  }

  

  React.useEffect(() => {
    fetch(
      envirment.proxyUrl + envirment.baseUrl + 'user_detail.php',
      {
        method: 'POST',
        body: JSON.stringify({ user_id: state.user_store_id }),
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/plain',
        },
      },
    ).then(response => {
      response.json().then(data => {
        console.log(data);
        if (data.length != 0) {
          if (data.profile_image !== '') {
            dispatch({ type: 'setUserProfile', payload: envirment.baseUrl+data.profile_image });
          }else{
            dispatch({ type: 'setUserProfile', payload: state.userprofile});
          }
          dispatch({ type: 'setMobileNumber', payload: data.mobile_number });
        }
      }
      
      );
    });
  }, [dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    
    let err = {};
    if (
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        state.email
      ) === false
    ) {
      document.querySelector("input[name=email]").focus();
      err = { ...err, email: "email is  invalid" };
    } else if (
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        state.mobileNumber
      ) === false
    ) {
      document.querySelector("input[name=mobileNumber]").focus();
      err = { ...err, mobileNumber: "Mobile Number is  invalid" };
    } else if (!Number(state.mobileNumber)) {
      err = { ...err, mobileNumber: "mobile Number should be number" };
    }
    
    else {
      err = null;
    }
    
    if (err) {
      dispatch({ type: 'setError', payload: { ...err } });
      console.log(err);
      return;
    }
    const notification = notificationSystem.current;
    dispatch({ type: 'setSubmitButtonText', payload: 'Updating...' });
    dispatch({ type: 'setSubmitButtonStyle', payload: { disabled: true } });
    const { email, mobileNumber, uid, userUploadImage } = state;
    console.log(state)
    const userData = {
      user_id: state.user_store_id,
      user_role_id: state.userType,
      email: email,
      mobile_number: mobileNumber,
      profile_image: userUploadImage,
    }

    fetch(envirment.proxyUrl + envirment.baseUrl + 'profile.php', {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain',

      },
    }).then(response => {
      response.json().then(data => {
        dispatch({ type: 'setSubmitButtonText', payload: 'Update' });
          if(data.success == 1){
            
            notification.addNotification({
              autoDismiss:0,
              message: 'Updated Successfully',
              level: 'success'
            });
          }
      })
    })

    console.log({ email, uid, mobileNumber, userUploadImage });
  };


  return (

    <Page>
      <Row className="profile-screen">
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardHeader>Profile</CardHeader>
            <CardBody>



              <Form onSubmit={handleSubmit} style={{ paddingTop: 20 }}>
                <FormGroup row className="profile-img d-flex justify-content-center align-items-center flex-column card-  body ">
                  <img src={state.userprofile} className="rounded-circle mb-3 image-profile" />
                  {/* <br/>
              <input type="file"  onChange={handleChangeProfile} /> */}
                </FormGroup>
                <FormGroup row className="choose-file d-flex justify-content-center align-items-center flex-column card-body ">
                  <input type="file" onChange={handleChangeProfile} />
                </FormGroup>
                <FormGroup row className="fild-name">

                  <Label for="exampleEmail" sm={2} md={3}>

                  </Label>
                  <Col sm={10} md={9}>
                    <Input
                      value={localStorage.getItem('user_role_name')}
                      disabled
                    />

            
                  </Col>
                </FormGroup>


                <FormGroup row className="fild-name">

                  <Label for="exampleEmail" sm={2} md={3}>
                    Email
                  </Label>
                  <Col sm={10} md={9}>
                    <Input
                      type="email"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      className={state.error.email ? 'is-invalid' : ''}
                    />

                    <invalid-feedback>{state.error.email}</invalid-feedback>
                  </Col>
                </FormGroup>

                <FormGroup row  className="fild-name">
                  <Label for="examplePassword" sm={2} md={3}>
                    Mobile Number
                  </Label>
                  <Col sm={10} md={9}>
                    <Input
                      type="text"
                      name="mobileNumber"
                      value={state.mobileNumber}
                      onChange={handleChange}
                      className={state.error.mobileNumber ? 'is-invalid' : ''}
                    />
                    <invalid-feedback>{state.error.mobileNumber}</invalid-feedback>
                  </Col>
                </FormGroup>
                

            
                <FormGroup check row className="update-btn">
                  <Col sm={{ size: 10, offset: 3 }} style={{ paddingTop: 30 }}>
                    <Button className="foodlynk_btn_color" onClick={handleSubmit} style={state.submitbuttonstyle}>{state.submitbuttontext}</Button>
                    <NotificationSystem ref={notificationSystem} />
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default AdminProfile;
