import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Polyline,
} from "react-google-maps";
import { Input } from "reactstrap";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "./client-config";

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

class Map extends Component {
  componentDidMount() {
    if (
      this.props.location.mapPosition.lat &&
      this.props.location.mapPosition.lng
    ) {
      Geocode.fromLatLng(
        this.props.location.mapPosition.lat,
        this.props.location.mapPosition.lng
      ).then(
        (response) => {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray);

          this.props.dispatch({
            type: "setLocation",
            payload: {
              address: address ? address : "",
              area: area ? area : "",
              city: city ? city : "",
              state: state ? state : "",
              mapPosition: {
                lat: this.props.location.mapPosition.lat,
                lng: this.props.location.mapPosition.lng,
              },
              markerPosition: {
                lat: this.props.location.markerPosition.lat,
                lng: this.props.location.markerPosition.lat,
              },
            },
          });
          if (this.props.isNew == 1) {
            this.props.dispatch({
              type: "setPlaces",
              payload: [
                {
                  address: address ? address : "",
                  area: area ? area : "",
                  city: city ? city : "",
                  state: state ? state : "",
                  markerPosition: {
                    lat: this.props.location.mapPosition.lat,
                    lng: this.props.location.markerPosition.lat,
                  },
                  mapPosition: {
                    lat: this.props.location.mapPosition.lat,
                    lng: this.props.location.markerPosition.lat,
                  },
                },
              ],
            });
          } else if (this.props.isNew == 0) {
            this.props.dispatch({
              type: "setPlaces",
              payload: [
                ...this.props.places,
                {
                  address: address ? address : "",
                  area: area ? area : "",
                  city: city ? city : "",
                  state: state ? state : "",
                  markerPosition: {
                    lat: this.props.location.mapPosition.lat,
                    lng: this.props.location.markerPosition.lat,
                  },
                  mapPosition: {
                    lat: this.props.location.mapPosition.lat,
                    lng: this.props.location.markerPosition.lat,
                  },
                },
              ],
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onInfoWindowClose = (event) => {};

  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    this.props.dispatch({
      type: "setLocation",
      payload: {
        address: address ? address : "",
        area: area ? area : "",
        city: city ? city : "",
        state: state ? state : "",
        markerPosition: {
          lat: latValue,
          lng: lngValue,
        },
        mapPosition: {
          lat: latValue,
          lng: lngValue,
        },
      },
    });
    this.props.dispatch({
      type: "setPlaces",
      payload: [
        ...this.props.places,
        {
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: latValue,
            lng: lngValue,
          },
          mapPosition: {
            lat: latValue,
            lng: lngValue,
          },
        },
      ],
    });
    if (this.props.places.length >= 1) {
      this.props.dispatch({ type: "setZoom", payload: 8 });
    }
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <div>
          <GoogleMap
            
            google={props.google}
            defaultZoom={this.props.zoom}
            defaultCenter={{
              lat: this.props.location.mapPosition.lat,
              lng: this.props.location.mapPosition.lng,
            }}
          >
            <Autocomplete
              className="map-input-box"
              // style={{
              //   width: "100%",
              //   height: "40px",
              //   paddingLeft: "16px",
              //   marginTop: "2px",
              // }}
              onPlaceSelected={this.onPlaceSelected}
              types={["(regions)"]}
            />
            {this.props.places.map((item, index) => (
              <>
                <Marker
                  key={index}
                  google={props.google}
                  name={"Dolores park"}
                  position={{
                    lat: item.markerPosition.lat,
                    lng: item.markerPosition.lng,
                  }}
                />
                <InfoWindow
                  onClose={this.onInfoWindowClose}
                  position={{
                    lat: item.markerPosition.lat + 0.0018,
                    lng: item.markerPosition.lng,
                  }}
                >
                  <div>
                    <span style={{ padding: 0, margin: 0 }}>
                      {item.address}
                    </span>
                  </div>
                </InfoWindow>
              </>
            ))}

            <Marker />
          </GoogleMap>
        </div>
      ))
    );
    let map;
    if (
      this.props.location.mapPosition.lat &&
      this.props.location.mapPosition.lng
    ) {
      map = (
        <div>
          {/* <div>
            <div className="form-group">
              
              <Input
                type="text"
                name="address"
                className="form-control"
                readOnly="readOnly"
                value={this.props.location.address}
              />
            </div>
          </div> */}

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%`, marginTop: `30px` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;

// import React, { Component } from 'react';
// import {
//   withGoogleMap,
//   GoogleMap,
//   withScriptjs,
//   InfoWindow,
//   Marker,
//   Polyline,
// } from 'react-google-maps';
// import {Input} from 'reactstrap';
// import Geocode from 'react-geocode';
// import Autocomplete from 'react-google-autocomplete';
// import { GoogleMapsAPI } from './client-config';
// Geocode.setApiKey(GoogleMapsAPI);
// Geocode.enableDebug();

// class Map extends Component {
//   /**
//    * Get the current address from the default map position and set those values in the state
//    */
//   componentDidMount() {
//     Geocode.fromLatLng(
//       this.props.location.mapPosition.lat,
//       this.props.location.mapPosition.lng,
//     ).then(
//       response => {
//         const address = response.results[0].formatted_address,
//           addressArray = response.results[0].address_components,
//           city = this.getCity(addressArray),
//           area = this.getArea(addressArray),
//           state = this.getState(addressArray);

//         // console.log('city', city, area, state);

//         this.props.dispatch({
//           type: 'setLocation',
//           payload: {
//             address: address ? address : '',
//             area: area ? area : '',
//             city: city ? city : '',
//             state: state ? state : '',
//             mapPosition: {
//               lat: this.props.location.mapPosition.lat,
//               lng: this.props.location.mapPosition.lng,
//             },
//             markerPosition: {
//               lat: this.props.location.markerPosition.lat,
//               lng: this.props.location.markerPosition.lat,
//             },
//           },
//         });
//       },
//       error => {
//         console.error(error);
//       },
//     );
//   }
//   /**
//    * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
//    *
//    * @param nextProps
//    * @param nextState
//    * @return {boolean}
//    */

//   /**
//    * Get the city and set the city input value to the one selected
//    *
//    * @param addressArray
//    * @return {string}
//    */
//   getCity = addressArray => {
//     let city = '';
//     for (let i = 0; i < addressArray.length; i++) {
//       if (
//         addressArray[i].types[0] &&
//         'administrative_area_level_2' === addressArray[i].types[0]
//       ) {
//         city = addressArray[i].long_name;
//         return city;
//       }
//     }
//   };
//   /**
//    * Get the area and set the area input value to the one selected
//    *
//    * @param addressArray
//    * @return {string}
//    */
//   getArea = addressArray => {
//     let area = '';
//     for (let i = 0; i < addressArray.length; i++) {
//       if (addressArray[i].types[0]) {
//         for (let j = 0; j < addressArray[i].types.length; j++) {
//           if (
//             'sublocality_level_1' === addressArray[i].types[j] ||
//             'locality' === addressArray[i].types[j]
//           ) {
//             area = addressArray[i].long_name;
//             return area;
//           }
//         }
//       }
//     }
//   };
//   /**
//    * Get the address and set the address input value to the one selected
//    *
//    * @param addressArray
//    * @return {string}
//    */
//   getState = addressArray => {
//     let state = '';
//     for (let i = 0; i < addressArray.length; i++) {
//       for (let i = 0; i < addressArray.length; i++) {
//         if (
//           addressArray[i].types[0] &&
//           'administrative_area_level_1' === addressArray[i].types[0]
//         ) {
//           state = addressArray[i].long_name;
//           return state;
//         }
//       }
//     }
//   };
//   /**
//    * And function for city,state and address input
//    * @param event
//    */
//   // onChange = (event) => {
//   //   this.setState({ [event.target.name]: event.target.value });
//   // };
//   /**
//    * This Event triggers when the marker window is closed
//    *
//    * @param event
//    */
//   onInfoWindowClose = event => {};

//   /**
//    * When the marker is dragged you get the lat and long using the functions available from event object.
//    * Use geocode to get the address, city, area and state from the lat and lng positions.
//    * And then set those values in the state.
//    *
//    * @param event
//    */
//   onMarkerDragEnd = event => {
//     let newLat = event.latLng.lat(),
//       newLng = event.latLng.lng();

//     Geocode.fromLatLng(newLat, newLng).then(
//       response => {
//         const address = response.results[0].formatted_address,
//           addressArray = response.results[0].address_components,
//           city = this.getCity(addressArray),
//           area = this.getArea(addressArray),
//           state = this.getState(addressArray);

//         this.props.dispatch({
//           type: 'setLocation',
//           payload: {
//             address: address ? address : '',
//             area: area ? area : '',
//             city: city ? city : '',
//             state: state ? state : '',
//             markerPosition: {
//               lat: newLat,
//               lng: newLng,
//             },
//             mapPosition: {
//               lat: newLat,
//               lng: newLng,
//             },
//           },
//         });
//         this.props.dispatch({
//           type: 'setPlaces',
//           payload: [
//             ...this.props.places,
//             {
//               address: address ? address : '',
//               area: area ? area : '',
//               city: city ? city : '',
//               state: state ? state : '',
//               markerPosition: {
//                 lat: newLat,
//                 lng: newLng,
//               },
//               mapPosition: {
//                 lat: newLat,
//                 lng: newLng,
//               },
//             },
//           ],
//         });
//       },
//       error => {
//         console.error(error);
//       },
//     );
//   };

//   /**
//    * When the user types an address in the search box
//    * @param place
//    */
//   onPlaceSelected = place => {
//     // console.log('plc', place);
//     const address = place.formatted_address,
//       addressArray = place.address_components,
//       city = this.getCity(addressArray),
//       area = this.getArea(addressArray),
//       state = this.getState(addressArray),
//       latValue = place.geometry.location.lat(),
//       lngValue = place.geometry.location.lng();
//     // Set these values in the state.

//     this.props.dispatch({
//       type: 'setLocation',
//       payload: {
//         address: address ? address : '',
//         area: area ? area : '',
//         city: city ? city : '',
//         state: state ? state : '',
//         markerPosition: {
//           lat: latValue,
//           lng: lngValue,
//         },
//         mapPosition: {
//           lat: latValue,
//           lng: lngValue,
//         },
//       },
//     });
//     this.props.dispatch({
//       type: 'setPlaces',
//       payload: [
//         ...this.props.places,
//         {
//           address: address ? address : '',
//           area: area ? area : '',
//           city: city ? city : '',
//           state: state ? state : '',
//           markerPosition: {
//             lat: latValue,
//             lng: lngValue,
//           },
//           mapPosition: {
//             lat: latValue,
//             lng: lngValue,
//           },
//         },
//       ],
//     });
//     if (this.props.places.length >= 1) {
//       this.props.dispatch({ type: 'setZoom', payload: 8 });
//     }
//   };

//   render() {
//     const AsyncMap = withScriptjs(
//       withGoogleMap(props => (
//         <GoogleMap
//           google={props.google}
//           defaultZoom={this.props.zoom}
//           defaultCenter={{
//             lat: this.props.location.mapPosition.lat,
//             lng: this.props.location.mapPosition.lng,
//           }}
//         >
//           {/* InfoWindow on top of marker */}
//           <InfoWindow
//             onClose={this.onInfoWindowClose}
//             position={{
//               lat: this.props.location.markerPosition.lat + 0.0018,
//               lng: this.props.location.markerPosition.lng,
//             }}
//           >
//             <div>
//               <span style={{ padding: 0, margin: 0 }}>
//                 {this.props.location.address}
//               </span>
//             </div>
//           </InfoWindow>
//           {/*Marker*/}
//           {this.props.places.length === 0 && (
//             <Marker
//               google={props.google}
//               name={'Dolores park'}
//               draggable={true}
//               onDragEnd={this.onMarkerDragEnd}
//               position={{
//                 lat: this.props.location.markerPosition.lat,
//                 lng: this.props.location.markerPosition.lng,
//               }}
//               icon={'./asset/img.png'}
//             />
//           )}

//           {this.props.places.map((item,index) => {
//             const mk1 = (
//               <Marker
//                 key={index}
//                 google={props.google}
//                 name={'Dolores park'}
//                 draggable={true}
//                 onDragEnd={this.onMarkerDragEnd}
//                 position={{
//                   lat: item.markerPosition.lat,
//                   lng: item.markerPosition.lng,
//                 }}
//               />
//             );
//             console.log(mk1);
//             return mk1;
//           })}
//           {/* <Polyline path={this.props.places.map(item => item.mapPosition)} /> */}
//           <Marker />
//           {/* For Auto complete Search Box */}
//           <Autocomplete
//             style={{
//               width: '100%',
//               height: '40px',
//               paddingLeft: '16px',
//               marginTop: '2px',
//               marginBottom: '500px',
//             }}
//             onPlaceSelected={this.onPlaceSelected}
//             types={['(regions)']}
//           />
//         </GoogleMap>
//       )),
//     );
//     let map;
//     if (this.props.location.mapPosition.lat !== undefined) {
//       map = (
//         <div>
//           <div>
//             <div className="form-group">
//               <label htmlFor="">Address</label>
//               <Input
//                 type="text"
//                 name="address"
//                 className="form-control"
//                 onChange={e =>
//                   this.props.dispatch({
//                     type: 'setLocation',
//                     payload: {
//                       ...this.props.location,
//                       address: e.target.value,
//                     },
//                   })
//                 }
//                 readOnly="readOnly"
//                 value={this.props.location.address}
//               />
//             </div>
//           </div>

//           <AsyncMap
//             googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
//             loadingElement={<div style={{ height: `100%` }} />}
//             containerElement={<div style={{ height: this.props.height }} />}
//             mapElement={<div style={{ height: `100%` }} />}
//           />
//         </div>
//       );
//     } else {
//       map = <div style={{ height: this.props.height }} />;
//     }
//     return map;
//   }
// }
// export default Map;
