export default function workerDev() {
  let workerURL = `${process.env.PUBLIC_URL}/worker.js`;
  navigator.serviceWorker.register(workerURL).then(response => {
    console.log('Response', response);
    return response.pushManager.getSubscription().then(function (subscription) {
      return response.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          'BGjV_68dfcn6R1mj9zLF-TrsNUfmociMEjqcwYDmpxHKhS7IeKBgakWdHKmmGeQIk8SRSOA2256YlO2NGxuiMqw',
      });
    });
  });
}
