import React, { Component } from "react";
import { Button, Card, CardBody, CardText, Input, Container } from "reactstrap";
import CSVReader from "react-csv-reader";
import envirment from "./base_url";
import Modal from "pages/Modal";
import SampleDonor from "../assets/sampleData/SampleDonor.csv";
import SampleVolunteer from "../assets/sampleData/SampleVolunteer.csv";
import { Link } from "react-router-dom";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class BulkUpload extends Component {
  constructor() {
    super();
    this.state = {
      userType: "",
      csvfile: undefined,
      csvData: [],
      wrongEmail: [],
      wrongMobile: [],
      invalidData: [],
      validData: [],
      showbutton: false,
      popup: false,
      nodatapopup: false,
    };
    this.submitData = this.submitData.bind(this);
    this.handleForce = this.handleForce.bind(this);
    this.userChange = this.userChange.bind(this);
  }

  handleForce = (data) => {
    let inValidEmail = [];
    let inValidMobileNumber = [];
    let invald = [];
    let valid = [];
    this.setState({ csvData: data });
    this.state.csvData.map((item1, index1) => {
      if (
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
          item1.email
        ) === false
      ) {
        var email = false;
      }
      if (/[0-9]{8,12}$/im.test(item1.mobileno) == false) {
        var mobileno = false;
      }

      if (email == false) {
        item1["email_vaild"] = 0;
      } else {
        item1["email_vaild"] = 1;
      }
      if (mobileno == false) {
        item1["mobileno_valid"] = 0;
      } else {
        item1["mobileno_valid"] = 1;
      }
      if (item1["email_vaild"] == 1 && item1["mobileno_valid"] == 1) {
        valid.push(item1);
        this.setState({ validData: valid });
      } else {
        invald.push(item1);
        this.setState({ invalidData: invald });
      }
    });

    this.setState({ wrongEmail: inValidEmail });
    this.setState({ wrongMobile: inValidMobileNumber });
  };

  userChange(e) {
    this.setState({
      userType: e.target.value,
    });
  }

  submitData() {
    fetch(envirment.proxyUrl + envirment.baseUrl + "bulk_upload.php", {
      method: "POST",
      body: JSON.stringify({
        data: this.state.validData,
        user_role_id: this.state.userType,
      }),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "text/plain",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        console.log("SUBMISSION", res);
        this.setState({ popup: true });
      });
    this.setState({ showButton: true });
  }

  render() {
    return (
      <div className="dsahboard-page records-data">
        <Container>
          <div className="ana-page bulk-upload">
            <div className="bulk-innder-data">
              <div className="sample-dataset">
                <a
                  className="sample-data-donor"
                  href={SampleDonor}
                  download="SampleDonor.csv"
                >
                  Dataset Donor
                </a>
                <a
                  className="sample-data-volunteer"
                  href={SampleVolunteer}
                  download="SampleVolunteer.csv"
                >
                  Dataset Volunteer
                </a>
              </div>
              <Input
                className="form-ana"
                type="select"
                name="select"
                onChange={this.userChange}
              >
                <option selected disabled>
                  Select Role
                </option>
                <option value="1">Donor</option>
                <option value="2">Volunteer</option>
              </Input>
              <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={this.handleForce}
                parserOptions={papaparseOptions}
              />
            </div>
            {this.state.validData.length > 0 ? (
              <p className="dsahboard-page title">Valid Details</p>
            ) : (
              ""
            )}
            {this.state.validData.length > 0 &&
              this.state.validData.map((item, index) => (
                <div key={index} style={{ marginBottom: "15px" }}>
                  <Card inverse className={`border text-dark`}>
                    <CardBody className="d-flex justify-content-between flex-column card-material">
                      <CardText>
                        <label>Username:</label>
                        <div className="address-records">
                          {item.firstname}&nbsp;
                          {item.lastname}
                        </div>
                      </CardText>
                      <CardText>
                        <label>Email:</label>
                        <div className="address-records">
                          {item.email}
                          {item.email_vaild == 0 ? "(Email is Invalid)" : ""}
                        </div>
                      </CardText>
                      <CardText>
                        <label>Mobile Number:</label>
                        <div className="address-records">
                          {item.mobileno}
                          {item.mobileno_valid == 0
                            ? "(Mobile Number is Invalid)"
                            : ""}
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            {this.state.invalidData.length > 0 ? (
              <p className="title">Invalid Details</p>
            ) : (
              ""
            )}
            {this.state.invalidData.length > 0 &&
              this.state.invalidData.map((item, index) => (
                <div key={index} style={{ marginBottom: "15px" }}>
                  <Card inverse className={`border text-dark`}>
                    <CardBody className="d-flex justify-content-between flex-column card-material">
                      <CardText>
                        <label>Username:</label>
                        <div className="address-records">
                          {item.firstname}&nbsp;
                          {item.lastname}
                        </div>
                      </CardText>
                      <CardText>
                        <label>Email:</label>
                        <div className="address-records">
                          {item.email}
                          {item.email_vaild == 0 ? "(Email is Invalid)" : ""}
                        </div>
                      </CardText>
                      <CardText>
                        <label>Mobile Number:</label>
                        <div className="address-records">
                          {item.mobileno}
                          {item.mobileno_valid == 0
                            ? "(Mobile Number is Invalid)"
                            : ""}
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            {this.state.validData.length > 0 ? (
              <div className="button-bulk">
                <Button onClick={this.submitData}>Submit</Button>
              </div>
            ) : (
              ""
            )}
            {this.state.invalidData.length > 0 ? (
              <div className="button-bulk">
                <Button onClick={this.submitData}>Submit</Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Container>
        <div className="modal-popup donor-name">
          <Modal show={this.state.popup}>
            <div className="data-inner">
              <div className="signup-head">
                Email has Been sent to the Provided E-mail
              </div>
              <Link to={"/HomeDashboard"} className="btn btn-primary">
                Ok
              </Link>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default BulkUpload;
