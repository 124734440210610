import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Card,
  CardText,
} from "reactstrap";
import envirment from "./base_url";
import config from "./firebaseConfig";
import { Link } from "react-router-dom";

class ModalPage extends React.Component {
  state = {
    modal: false,
    modal_backdrop: false,
    backdrop: "static",
    recipient: "",
    recipientList: [],
  };
  toggle = (modalType, accept) => () => {
    if (!modalType) {
      return this.setState({
        modal: !this.state.modal,
      });
    }
    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });

    if (accept) {
      this.props.handleAccept();

      fetch(
        envirment.proxyUrl +
          envirment.baseUrl +
          "receiptant_list_using_food_id.php",
        {
          method: "POST",
          body: JSON.stringify({ food_id: Number(this.props.food_id) }),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((res) => {
          console.log(res);
          this.setState({ recipientList: res });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleChange = (e) => {
    this.setState({ recipient: e.target.id });
  };

  handleSubmit = () => {
    if (this.state.recipient === "") {
      alert("Please select at least one recipient");
    } else {
      let rec_id = this.state.recipient;
      console.log(rec_id);

      if (!rec_id) {
        return;
      }

      fetch(
        envirment.proxyUrl + envirment.baseUrl + "vol_accept_receiptant.php",
        {
          method: "POST",
          body: JSON.stringify({
            food_id: Number(this.props.food_id),
            rec_id: rec_id,
          }),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((res) => {
          config
            .database()
            .ref("foodrequest")
            .set({ food_id: this.props.food_id });

          console.log(this);

          this.setState({
            modal: false,
            modal_backdrop: false,
          });

          window.location = "/onair";
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <div className="accept-btn">
        <Button onClick={this.toggle("backdrop", "accept")}>Accept</Button>
        <Modal
          className="select-modal recipient-list"
          isOpen={this.state.modal_backdrop}
          toggle={this.toggle("backdrop")}
          backdrop={this.state.backdrop}
        >
          <ModalHeader>Select Recipient</ModalHeader>
          <ModalBody>
            {this.state.recipientList == null ? (
              <div
              className="recipient-modal-text"
              style={{ textAlign: "center", fontSize: "14px" }}
            >
              <p>
                There is no Recipient in your area. Please increase the
                Volunteering Radius
              </p>
            </div>
            ) : (
              ""
            )}
            <div className="recipient-details">
              {this.state.recipientList != undefined &&
                this.state.recipientList.map((item, index) => {
                  return (
                    <div className="row" key={index}>
                      <Col md={12} sm={12} xs={12} className="mb-3">
                        <Card
                          inverse
                          style={{ alignItems: "center" }}
                          className={`border text-dark`}
                        >
                          <h4>
                            {item.first_name} {item.last_name}
                          </h4>
                          <p>{item.address}</p>
                          <p>Distance: {item.distance} Miles</p>

                          <input
                            type="radio"
                            id={item.receiptant_id}
                            value={item.address}
                            name="recipient"
                            // checked={this.state.recipient === item.address}
                            onChange={this.handleChange}
                          />
                        </Card>
                      </Col>
                    </div>
                  );
                })}
            </div>
          </ModalBody>
          <ModalFooter style={{ textAlign: "center" }}>
            {this.state.recipientList == null ? (
              <Link className="go-back-profile" to="/profile">
                Go Back to Profile
              </Link>
            ) : (
              <Button color="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalPage;
