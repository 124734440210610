import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const ProtectedRecipeientRoute = ({ component: Component, ...rest }) => (
    
    <Route
       
        {...rest}
        render={(props) => (
            localStorage.getItem('user_id') !==null ? 
                localStorage.getItem('user_role_id') == '3'? (
                <Component {...props} />
            ) :
                <Redirect to="/dashboard" />  :
            <Redirect to="/" /> 


            // console.log('hill'),
            // localStorage.getItem('recipeient') && 
            // localStorage.getItem('user_id') !==null && localStorage.getItem('user_role_id') == '3'? (
            //     <Component {...props} />
            // ) :
            //     <Redirect to="/dashboard" /> 
        )}
    />
);

export default ProtectedRecipeientRoute;