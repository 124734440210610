import Avatar from "components/Avatar";
import logo_t from "assets/img/logo/logo_t.png";
import Notifications from "components/Notifications";
import SearchInput from "components/SearchInput";
import { notificationsData } from "demos/header";
import withBadge from "hocs/withBadge";
import React ,{componentDidCatch}from "react";
import userImage from "../../assets/img/user.jpg";
import sidebar_open from "assets/img/sidebar/sidebar_open.png";
import config from "../../pages/firebaseConfig";
import { GeolocatedProps, geolocated } from "react-geolocated";
import envirment from "../../pages/base_url";
import * as rdd from "react-device-detect";
import {
  MdClearAll,
  MdExitToApp,
  MdNotificationsActive,
  MdPersonPin,
} from "react-icons/md";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from "reactstrap";
import bn from "utils/bemnames";
import { Link, Redirect } from "react-router-dom";

const bem = bn.create("header");

const MdNotificationsActiveWithBadge = withBadge({
  size: "md",
  color: "primary",
  style: {
    top: -10,
    right: -10,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    latitude: "",
    longitude: "",
    firstName: localStorage.getItem("first_name"),
    lastName: localStorage.getItem("last_name"),
    latlng: [],
    session: 1,
    token: "",
    // modal1: false,
  };
  constructor(props) {
    super(props);
  //  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  this.state = { error: null, errorInfo: null };
  
    var url = "check_user.php";
    fetch(envirment.proxyUrl + envirment.baseUrl + url, {
      method: "POST",
      body: JSON.stringify({ user_id: localStorage.getItem("user_id") }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        if (res.success == 1) {
          localStorage.setItem("session", res.time);
          localStorage.setItem("is_new", res.is_new);
        } else if (res.success == 0) {
          // window.location = "/signout";
          this.setState({
            session: 0,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  const componentDidCatch = (error, errorInfo) => {
        // Catch errors in any components below and re-render with error message
        console.log('error')
        this.setState({
          error: error,
          errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
      }

    const interval = setInterval(() => {
      if (
        localStorage.getItem("session") == undefined ||
        localStorage.getItem("session") == null ||
        localStorage.getItem("session") < Date.now()
      ) {
        var url = "check_user.php";
        fetch(envirment.proxyUrl + envirment.baseUrl + url, {
          method: "POST",
          body: JSON.stringify({ user_id: localStorage.getItem("user_id") }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
          })
          .then((res) => {
            console.log(res);
            if (res.success == 1) {
              localStorage.setItem("session", res.time);
              localStorage.setItem("is_new", res.is_new);
            } else if (res.success == 0) {
              this.setState({
                session: 0,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }, 10000);
  }
  getLocation = () => {
    // if(localStorage.getItem('ride_start') != undefined){
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(this.showPosition);
    } else {
      console.log("Geo Location not supported by browser");
    }
  };
  // chlocation(pos){

  //   this.setState({
  //     latlng:{
  //       lat:(this.props.coords && this.props.coords.latitude),
  //       lng: (this.props.coords && this.props.coords.longitude)
  //     }
  //   })
  //   console.log('pos',pos)
  //   console.log('let',this.state.latlng)
  //   if(localStorage.getItem('user_id') != undefined){
  //       config1.database().ref('locations').child(localStorage.getItem('user_id')).set({
  //         latitude: this.state.latlng.lat,
  //         longitude: this.state.latlng.lng,
  //       });
  // }
  // }
  // }
  showPosition = (position) => {
    console.log("position -- ",position)
    if (localStorage.getItem("user_id") != undefined) {
      this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    }
  };
  componentDidMount() {
    // if (
    //   localStorage.getItem("service_area") == undefined ||
    //   localStorage.getItem("service_area") == ""
    // ) {
    //   this.setState({
    //     modal1: true,
    //   });
    // }
    setInterval(() => {
      try {
        if (rdd.isIOS == false) {
          if (rdd.isMacOs == true) {
            if (rdd.isSafari == false) {
              const messaging = config.messaging();
              messaging
                .requestPermission()
                .then((token) => {
                  return messaging.getToken();
                })
                .then((token) => {
                  // console.log(token);
                  config.database().ref("Users").set({ token: token });
                  this.setState({
                    token: token,
                  });
                  // console.log(this.state);
                })
                .catch(() => {
                  console.log("error");
                });

              // messaging.onMessage((payload) => {
              //   console.log("Message received. ", payload);
              //   alert("Hey");
              // });
            }
          } else {
            const messaging = config.messaging();
            messaging
              .requestPermission()
              .then((token) => {
                return messaging.getToken();
              })
              .then((token) => {
                console.log(token);
                config.database().ref("Users").set({ token: token });
                this.setState({
                  token: token,
                });
                // console.log(this.state);
              })
              .catch(() => {
                console.log("error");
              });
          }
        }

        fetch(envirment.proxyUrl + envirment.baseUrl + "updatetoken.php", {
          method: "POST",
          body: JSON.stringify({
            token: this.state.token,
            user_id: localStorage.getItem("user_id"),
          }),
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain",
          },
        }).then((response) => {});
      } catch (error) {}
    }, 20000);

    // if (localStorage.getItem("user_id") != undefined) {
    //   this.getLocation();
    //   setInterval(() => {
    //     this.getLocation();
    //     config
    //       .database()
    //       .ref("locations")
    //       .child(localStorage.getItem("user_id"))
    //       .set({
    //         latitude: this.state.latitude,
    //         longitude: this.state.longitude,
    //       });
    //   }, 3000);
    // }
const getcurr_post = () =>{
  if (navigator.geolocation) {
          var location_timeout = setTimeout("getcurr_post()", 10000);

          navigator.geolocation.getCurrentPosition(function(position) {
              clearTimeout(location_timeout);

              var lat = position.coords.latitude;
              var lng = position.coords.longitude;
              console.log(lat +" "+ lng)
              // geocodeLatLng(lat, lng);
              config
          .database()
          .ref("locations")
          .child(localStorage.getItem("user_id"))
          .set({
            latitude: lat,
            longitude: lng,
          });
          }, function(error) {
              clearTimeout(location_timeout);
              getcurr_post();
          });
      } else {
          // Fallback for no geolocation
          getcurr_post();
      }
    }

    




  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector(".cr-sidebar").classList.toggle("cr-sidebar--open");
  };

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <>
        {this.state.session == 0 ? <Redirect to="/signout" /> : ""}

        <Navbar light expand className={bem.b("bg-white")}>
          <Nav navbar className="mr-2">
            <Button
              className="toggle-icon"
              outline
              onClick={this.handleSidebarControlButton}
            >
              <img src={sidebar_open} />
            </Button>
          </Nav>
          <div className="h-logo">
            <a href={envirment.appUrl + "Dashboard"}>
              <img src={logo_t} />
            </a>
          </div>
          <Nav navbar className={bem.e("nav-right")}>
            <NavItem>
              <NavLink href="/profile">
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                  src={userImage}
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        {/* <div className="donor-name">
          <Modal
            name="location"
            show={this.state.modal1}
            backdrop="static"
            keyboard={false}
          >
            <ModalHeader>Message</ModalHeader>
            <ModalBody>
              You will be able to use the app only after completion of profile
            </ModalBody>

            <ModalFooter>
              <Link className="btn btn-primary" to="/profile">
                Ok
              </Link>
            </ModalFooter>
          </Modal>
        </div> */}
      </>
    );
  }
}

export default geolocated()(Header);

{
  /*import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React from 'react';
import userImage from '../../assets/img/user.jpg';
import config from '../../pages/firebaseConfig';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {


  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    latitude:"",
    longitude:""
  };
  

  getLocation =()=> {
    // if(localStorage.getItem('ride_start') != undefined){
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(this.showPosition);
      } else {
        console.log("Geo Location not supported by browser");
      }
    // }
    
  }
  showPosition = (position) => {
    // console.log(position)
    if(localStorage.getItem('user_id') != undefined){
    this.setState({
      latitude:position.coords.latitude,
      longitude: position.coords.longitude
    })
    }
  }

  componentDidMount() {
    
      this.getLocation();
            if(localStorage.getItem('user_id') != undefined){
          setInterval(() => {
            this.getLocation();
            config.database().ref('locations').child(localStorage.getItem('user_id')).set({
              latitude: this.state.latitude,
              longitude: this.state.longitude,
            });
          }, 1000);
      }

  }



  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>
          <SearchInput />
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {/* {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )} */
}
{
  /*} </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem>

          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
                src={userImage}
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
               
                  <ListGroup flush>
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem> */
}
{
  /*<a href="/signout" > 
                    <ListGroupItem tag="button" action="/signout" className="border-light">
                    <MdExitToApp /> Signout
                    </ListGroupItem></a>
                  </ListGroup>
                
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header; */
}
