import logo_t from "assets/img/logo/logo_t.png";
import icon1 from "assets/img/icon1.png";
import icon2 from "assets/img/icon2.png";
import icon3 from "assets/img/icon3.png";
import info from "assets/img/information/info.png";
import info1 from "assets/img/information/info1.png";
import info2 from "assets/img/information/info2.png";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, Form, FormGroup, Input, Label, Col } from "reactstrap";
import NotificationSystem from "react-notification-system";
import envirment from "../pages/base_url";
import config from "../pages/firebaseConfig";
import Modal from "pages/Modal";
import * as rdd from "react-device-detect";
import { fileUpload } from "fuctbase64";
import { Link, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// const axios = require('axios');

// var app = express();

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    // console.log( this.props.location.location)
    var usety = "2";
    if(props.location.location.state != undefined){
      usety = props.location.location.state.params.userType;
    }
    // console.log('da',props.location.location.state.params.userType)
    this.state = {
      fullName: "",
      email: "",
      password: "",
      role: "",
      disabled: "",
      name: "",
      userType: usety,
      confrim: "",
      isChecked: true,
      alertcolor: null,
      alertmsg: null,
      alertstyle: { display: "none" },
      token: "",
      referralCode: "",
      popup: false,
      popup2: false,
      popup3: false,
      modal: false,
      volm: false,
      donm: false,
      recm: false,
      emailstatus: false,
      token_exp: 1,
      reset_token_user_id: "",
      captcha: "",
      mobile_no:"",
      phone_code:"",
      err_name:"",
      err_email:"",
      err_mobile:"",
      err_password:"",
      err_crm_password:"",
      err_captcha:"",
      err_isCheck:""
      
      
    };

    this.checkChange = this.checkChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    //this.onChangeValue = this.onChangeValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClickRecaptcha = this.onClickRecaptcha.bind(this);
  }

  notificationSystem = React.createRef();

  addNotification = (event) => {};

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  //request for location

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  get isForgetPassword() {
    return this.props.authState === STATE_FORGET;
  }

  get isSTATE_NEWPASSWORD() {
    return this.props.authState === STATE_NEWPASSWORD;
  }
  changeAuthState = (authState) => (event) => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  componentDidMount() {
    if (this.props.authState === STATE_SIGNUP) {
    document.querySelector('input[type="tel"]').disabled = true;
    }
    var datetime = new Date();
    console.log("Server Date Time", datetime);

    if (this.props.referralCode !== undefined) {
      this.setState({
        referralCode: this.props.referralCode,
      });
    }
    try {
      if (rdd.isIOS == false) {
      if (rdd.isMacOs == true) {
        if (rdd.isSafari == false) {
          const messaging = config.messaging();
          messaging
            .requestPermission()
            .then((token) => {
              return messaging.getToken();
            })
            .then((token) => {
              // console.log(token);
              config.database().ref("Users").set({ token: token });
              this.setState({
                token: token,
              });
              // console.log(this.state);
            })
            .catch(() => {
              console.log("error");
            });

          // messaging.onMessage((payload) => {
          //   console.log("Message received. ", payload);
          //   alert("Hey");
          // });
        }
      } else {
        const messaging = config.messaging();
        messaging
          .requestPermission()
          .then((token) => {
            return messaging.getToken();
          })
          .then((token) => {
            console.log(token);
            config.database().ref("Users").set({ token: token });
            this.setState({
              token: token,
            });
            // console.log(this.state);
          })
          .catch(() => {
            console.log("error");
          });
      }
    }
    } catch (error) {
      // ...
    }
    

    // if (
    //   (rdd.isMacOs == false && rdd.isSafari == false)&&
    //   (rdd.isIOS == false && rdd.isSafari == false) &&
    //   (rdd.isIOS == false && rdd.isChrome == false) &&
    //   (rdd.isIOS == false && rdd.isFirefox == false)
    // ) {
    //   const messaging = config.messaging();

    //   messaging
    //     .requestPermission()
    //     .then((token) => {
    //       return messaging.getToken();
    //     })
    //     .then((token) => {
    //       // console.log(token);
    //       config.database().ref("Users").set({ token: token });
    //       this.setState({
    //         token: token,
    //       });
    //       // console.log(this.state);
    //     })
    //     .catch(() => {
    //       console.log("error");
    //     });
    //   messaging.onMessage((payload) => {
    //     console.log("Message received. ", payload);
    //     alert("Hey");
    //   });
    // }

    if (this.props.authState === STATE_NEWPASSWORD) {
      console.log(this.props.location.match.params.token);
      if (this.props.location.match.params.token != undefined) {
        fetch(envirment.proxyUrl + envirment.baseUrl + "resetpassword.php", {
          method: "POST",
          body: JSON.stringify({
            token: this.props.location.match.params.token,
          }),
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain",
          },
        }).then((response) => {
          response.json().then((data) => {
            console.log(data);
            if (data.success == 1) {
              this.setState({
                reset_token_user_id: data.user_id,
              });
            } else {
              this.setState({
                alertstyle: {
                  display: "block",
                },
                alertmsg: data.message,
                alertcolor: "secondary",
                token_exp: 0,
              });
            }
          });
        });
      }
    }
  }

  checkChange(e) {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  modalOpenn(e) {
    this.setState({ [e.target.name]: true });
  }

  modalOpen(e) {
    console.log("open", e.target.name);
    this.setState({ [e.target.name]: true });
  }

  modalClose(e) {
    console.log("close", e);
    this.setState({
      [e]: false,
    });
  }

  handleSubmit = (event) => {
    
    if (this.props.authState === STATE_FORGET) {
      var err_email = ""
      this.setState({
        err_email :""
      })
      if (this.state.email.length > 0 ) {
        var pverify = true; 
        var everify = true;
        if (
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            this.state.email
          ) === false
        ) {
          everify = false;
        } else {
          everify = true;
        }
        if (/([0-9]{10})/.test(this.state.email) === false) {
          pverify = false;
        } else {
          pverify = true;
        }

        if (pverify == false && everify == false) {
          this.setState({
            err_email :"Email is invalid."
          })
        } else {
          this.setState({
            emailstatus: true,
          });
        }

        if (this.state.emailstatus) {
          this.setState({
            alertstyle: {
              display: "none",
            },
          });
          const userData = {
            email: this.state.email,
            phone_verify: pverify,
            email_verify: everify,
          };
          fetch(
            envirment.proxyUrl + envirment.baseUrl + "forget_password.php",
            {
              method: "POST",
              body: JSON.stringify(userData),
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "text/plain",
              },
            }
          ).then((response) => {
            response.json().then((data) => {
              if (data.success == 1) {
                // const notification = this.notificationSystem.current;
                // notification.addNotification({
                //   autoDismiss: 0,
                //   message: "Password sent on your registered Email",
                //   level: "success",
                // });

                this.setState({
                  popup2:true,
                  email: "",
                });
              }else if(data.success == 0 && data.deactive == 1){
                this.setState({
                  alertstyle: {
                    display: "block",
                  },
                  alertmsg: "Your Account is Deactivated By Admin",
                  alertcolor: "secondary",
                });
              }
               else {
                this.setState({
                  alertstyle: {
                    display: "block",
                  },
                  alertmsg: "Email is not registered.",
                  alertcolor: "secondary",
                });
              }

              // return <Redirect to='/Profile'  />
            });
          });
        }
      }else{
        this.setState({
          err_email :"Email is Mandatory."
        })
      }
    }

    if (this.props.authState === STATE_NEWPASSWORD) {
      if (this.state.password !== this.state.confrim) {
        this.setState({
          alertstyle: {
            display: "block",
          },
          alertmsg: "Password doesn't match.",
          alertcolor: "secondary",
        });
      }
      else if(this.state.password.length < 8 ||  this.state.password.length > 20){
        this.setState({
          alertstyle: {
            display: "block",
          },
          alertmsg: "Password should be between 8 to 20 characters and should contain minimum of 1 number, 1 character & 1 special character.",
          alertcolor: "secondary",
        });
      } 
      else if (
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(
          this.state.password
        ) === false
      ) {
        this.setState({
          alertstyle: {
            display: "block",
          },
          alertmsg: "Password should be between 8 to 20 characters and should contain minimum of 1 number, 1 character & 1 special character.",
          alertcolor: "secondary",
        });
      }
      else {
        const userData = {
          password: this.state.password,
          user_id: this.state.reset_token_user_id,
        };
        fetch(envirment.proxyUrl + envirment.baseUrl + "newpassword.php", {
          method: "POST",
          body: JSON.stringify(userData),
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain",
          },
        }).then((response) => {
          response.json().then((data) => {
            if (data.success == 1) {
              this.setState({
                popup3:true
              })
              // const notification = this.notificationSystem.current;
              // notification.addNotification({
              //   autoDismiss: 0,
              //   message: "Password Changed Succesfully.",
              //   level: "success",
              // });
              // setTimeout(() => {
              //   return <Redirect to="/login" />;
              // }, 2000);
            }

            //   this.setState({
            //     email: "",
            //   });
            // } else {
            //   this.setState({
            //     alertstyle: {
            //       display: "block",
            //     },
            //     alertmsg: "Email is not registered",
            //     alertcolor: "secondary",
            //   });
            // }

            // return <Redirect to='/Profile'  />
          });
        });
      }

      // }
    }

    if (this.props.authState === STATE_SIGNUP) {
      var  err_crm_password=""
      var err_email=""
      var err_mobile=""
      var err_name=""
      var err_password=""
      var err_captcha=""
      var err_isCheck=""

  if(this.state.fullName == ""){
     err_name="Name is Mandatory."
  }
  if(this.state.email == ""){
        err_email="Email is Mandatory."
  }else if (
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
      this.state.email
    ) === false
  ) {
    err_email = "Email is invalid.";
  }
  if(this.state.mobile_no == ""){
    err_mobile = "Mobile is Mandatory."
  }else if (/[0-9]{8,12}$/im.test(this.state.mobile_no) === false) {
    err_mobile = "Mobile number is invalid."
  }
  if(this.state.password == ""){
    err_password = "Password is Mandatory."
  }else if (
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(
      this.state.password
    ) === false
  ) {
      err_password = "Password pattern is not valid."
    }
  if(this.state.confrim == ""){
    err_crm_password = "Confirm Password is Mandatory."
  }else if (this.state.password !== this.state.confrim) {
    err_crm_password = "Password doesn't match."
  }
  if (!this.state.isChecked) {
    err_isCheck = "Please accept terms and conditions."
  }else{
    err_isCheck = ""
  }
 
  if (this.state.captcha.length == 0) {
    
    err_captcha = "Please verify that you are not a robot."
  }
  this.setState({
    alertstyle: {
      display: "none",
    },
    alertcolor: "primary",
  });
  if (err_crm_password.length == 0 && err_email.length == 0 && err_mobile.length == 0 && err_name.length ==0 && err_password.length == 0 && err_isCheck.length == 0 && err_captcha.length == 0) {
    var pverify = true;
    console.log("tru1111e")
    var everify = true;
    
    const userData = {
      fullName: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      role: this.state.role,
      user_role_id: this.state.userType,
      fcm_token: this.state.token,
      isChecked: this.state.isChecked,
      phone_verify: "",
      email_verify: everify,
      mobile_no:this.state.mobile_no,
      phone_code:this.state.phone_code
    };
     
      if(err_crm_password.length == 0 && err_email.length == 0 && err_mobile.length == 0 && err_name.length ==0 && err_password.length == 0) {
        localStorage.setItem("mobile_number", this.state.email);

        this.setState({
          alertcolor: "primary",
        });
        fetch(envirment.proxyUrl + envirment.baseUrl + "register.php", {
          method: "POST",
          body: JSON.stringify(userData),
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain",
          },
        }).then((response) => {
          response.json().then((data) => {
            this.setState({
              alertstyle: {
                display: "none",
              },
              alertcolor: "primary",
            });
            console.log('dd',data.success)
            if (data.success == 1) {
             
                this.setState({
                  popup: true,
                });
              
              this.setState({
                fullName: "",
                email: "",
                password: "",
                confrim: "",
                role: "",
                alertcolor: "",
              });
            } else {
              this.setState({
                alertstyle: {
                  display: "block",
                },
                alertmsg: data.message,
                alertcolor: "secondary",
              });
            }
          });
        });
      }
    
  } else {
    
  }
  this.setState({
    err_crm_password:err_crm_password,
    err_email:err_email,
    err_mobile:err_mobile,
    err_name:err_name,
    err_password:err_password,
    err_isCheck:err_isCheck,
    err_captcha:err_captcha
  })
  // console.log(userData);
}

    if (this.props.authState === STATE_LOGIN) {
      if (this.state.password !== "" && this.state.email !== "") {
        const userData = {
          email: this.state.email,
          password: this.state.password,
          fcm_token: this.state.token,
        };
        this.setState({
          alertstyle: {
            display: "block",
          },
          alertmsg: "Login...",
          alertcolor: "primary",
        });

        fetch(envirment.proxyUrl + envirment.baseUrl + "login.php", {
          method: "POST",
          body: JSON.stringify(userData),
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain",
          },
        }).then((response) => {
          response.json().then((data) => {
            console.log(data);
            if (data.success == 1) {
              localStorage.setItem("user_id", data.user_id);
              localStorage.setItem("user_role_id", data.user_role_id);
              localStorage.setItem("email", data.email);
              localStorage.setItem("user_role_name", data.user_role_name);
              localStorage.setItem("cheapter_lead", data.cheapter_status);
              localStorage.setItem("service_area", data.service_area);
              localStorage.setItem("is_new", data.is_new);
              if (data.service_area.length > 0 ) {
                window.location = "/HomeDashboard"; 
              } else {
                window.location = "/dashboard";
              }
              if (localStorage.getItem("user_role_id") == "4") {
                window.location = "/HomeDashboard";
              }
            } else if (data.success == 2) {
              this.setState({
                alertstyle: {
                  display: "block",
                },
                alertmsg: "Your account not verified by Administrator",
                alertcolor: "secondary",
              });
            } else if (data.success == 3) {
              this.setState({
                alertstyle: {
                  display: "block",
                },
                alertmsg: "Account not verified. Kindly check your email",
                alertcolor: "secondary",
              });
            } else if (data.success == 4) {
              this.setState({
                alertstyle: {
                  display: "block",
                },
                alertmsg:
                  "Your account has been deactivated by the administrator",
                alertcolor: "secondary",
              });
            } else {
              this.setState({
                alertstyle: {
                  display: "block",
                },
                alertmsg: "Incorrect Email or Password",
                alertcolor: "secondary",
              });
            }
          });
        });
      } else {
        this.setState({
          alertstyle: {
            display: "block",
          },
          alertmsg: "Email Id & Password Required",
          alertcolor: "secondary",
        });
      }
    }

    event.preventDefault();
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return "Login";
    }

    if (!buttonText && this.isSignup) {
      return "Signup";
    }

    if (!buttonText && this.isForgetPassword) {
      return "Submit";
    }
    if (!buttonText && this.isSTATE_NEWPASSWORD) {
      return "Submit";
    }
    return buttonText;
  }

  handleOptionChange(event) {
    this.setState({ userType: event.target.value });
  }

  handleChange(event) {
    
    if(event.target.name != 'mobile_no'){
      this.setState({
        [event.target.name]: event.target.value,
      });
    }else{
      if (event.target.value[event.target.value.length - 1] !== ".") {
        if (Number.isInteger(Number(event.target.value))) {
          if (event.target.value.length <= 8) {
            this.setState({
              [event.target.name]: event.target.value,
            });
          } else if (event.target.value.length <= 12) {
            this.setState({
              [event.target.name]: event.target.value,
            });
          }
        }
      }
    }
     
        // dispatch({ type: 'setMobileNumber', payload: e.target.value });
        
  }

  onChangeValue(event) {
    console.log(event);
    this.setState({ userType: event.target.value });
  }

  onClickRecaptcha(value) {
    if (value == null) {
      this.setState({
        captcha: "",
      });
    } else {
      this.setState({
        captcha: value,
      });
    }
  }
  

  render() {
    const {
      showLogo,
      fullnameInputProps,
      usernameInputProps,
      passwordInputProps,
      confirmPasswordInputProps,
      referelInputProps,
      roleInputProps,
      children,
      mobile_no,
    } = this.props;

    return (
      <>
        {localStorage.getItem("user_id") !== null ? (
          <Redirect to="/dashboard" />
        ) : (
          ""
        )}
        <Form onSubmit={this.handleSubmit}>
          {showLogo && (
            <div className="text-center pb-4 logo-sec">
              <Link to="/">
              <img
                src={logo_t}
                className="rounded"
                style={{ width: 156, cursor: "pointer" }}
                alt="logo"
                // onClick={window.location =}
              />
              </Link>
            </div>
          )}
          <div class="form_sec">
            <div className="login-box">
              <div className="login-tab">
                <a
                  href="#login"
                  onClick={this.changeAuthState(STATE_LOGIN)}
                  className={
                    this.props.authState == STATE_LOGIN ? "active" : ""
                  }
                >
                  Login
                </a>
              </div>
              <div className="login-tab">
                {" "}
                <a
                  href="#signup"
                  onClick={this.changeAuthState(STATE_SIGNUP)}
                  className={
                    this.props.authState == STATE_SIGNUP ? "active" : ""
                  }
                >
                  Register
                </a>
              </div>
            </div>
            {this.isSignup && (
              <div className="donor-name">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="UserType"
                      id="volunteer"
                      value="2"
                      onChange={this.handleOptionChange}
                      defaultChecked ={this.state.userType == "2" ? true : false}
                    />{" "}
                    Volunteer
                    <img
                      src={info1}
                      id="recipient"
                      href="javascript:;"
                      name="volm"
                      onClick={(e) => this.modalOpen(e)}
                    />
                  </Label>

                  <Modal
                    name="volm"
                    show={this.state.volm}
                    handleClose={(e) => this.modalClose("volm")}
                  >
                    <div className="data-inner">
                      <h2>I am a Volunteer</h2>
                      <ul>
                        <li>
                          <img src={icon1} />
                        </li>
                        <li>Volunteers Time</li>
                        <li>Collects food from Donor & Deliver to Recipient</li>
                        <li>Typical eg You and Me</li>
                      </ul>
                    </div>
                  </Modal>
                </FormGroup>
                <FormGroup check inline>
                  <div>
                    <Label >
                      <Input
                        name="UserType"
                        value="1"
                        type="radio"
                        onChange={this.handleOptionChange}
                        defaultChecked ={this.state.userType == "1"? true : false}
                      />{" "}
                      Donor
                      <img
                        src={info}
                        href="javascript:;"
                        id="donor"
                        name="donm"
                        onClick={(e) => this.modalOpen(e)}
                      />
                    </Label>
                    <Modal
                      name="donm"
                      show={this.state.donm}
                      handleClose={(e) => this.modalClose("donm")}
                    >
                      <div className="data-inner">
                        <h2>I am a Donor</h2>
                        <ul>
                          <li>
                            <img src={icon3} />
                          </li>
                          <li>Donates Food</li>
                          <li>Communicates about surplus food availability</li>
                          <li>Typical eg Restaurants</li>
                        </ul>
                      </div>
                    </Modal>
                  </div>
                </FormGroup>

                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="UserType"
                      value="3"
                      onChange={this.handleOptionChange}
                      defaultChecked ={this.state.userType == "3"? true : false}
                    />{" "}
                    Recipient
                    <img
                      src={info2}
                      id="recipient"
                      href="javascript:;"
                      name="recm"
                      onClick={(e) => this.modalOpen(e)}
                    />
                  </Label>
                  <Modal
                    name="recm"
                    show={this.state.recm}
                    handleClose={(e) => this.modalClose("recm")}
                  >
                    <div className="data-inner">
                      <h2>I am a Recipient</h2>
                      <ul>
                        <li>
                          <img src={icon2} />
                        </li>
                        <li>Recieves Food</li>
                        <li>Communicates about the requirement of needy</li>
                        <li>Typical eg Homeless Shelter</li>
                      </ul>
                    </div>
                  </Modal>
                </FormGroup>
                {/* <FormGroup check inline>
                  <Label check> 
                    <Input
                      type="radio"
                      name="UserType"
                      id="chapterlead"
                      value="5"
                      onChange={this.handleOptionChange}
                    />{" "}
                    Chapter Lead
                    <img
                      src={info1}
                      id="recipient"
                      href="javascript:;"
                      onClick={(e) => this.modalOpen(e)}
                    />
                  </Label>
                </FormGroup> */}
              </div>
            )}
            <div>
              <div>
                {this.isSignup && (
                  <FormGroup>
                    <Input
                      {...fullnameInputProps}
                      name="fullName"
                      value={this.state.fullName}
                      required
                      onChange={this.handleChange}
                      className={this.state.err_name !="" ? "is-invalid" : ""}
                    />
                    {this.state.err_name !="" ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_name}
                    </Alert>:""}
                    
                  </FormGroup>

                )}
                {!this.isSTATE_NEWPASSWORD && (
                  <FormGroup>
                    <Input
                      {...usernameInputProps}
                      name="email"
                      value={this.state.email}
                      required
                      onChange={this.handleChange}
                      className={this.state.err_email !="" !="" ? "is-invalid" : ""}
                    />
                    {this.state.err_email !="" ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_email}
                    </Alert>:""}
                  </FormGroup>
                )}
                {this.isSignup && (
                  <FormGroup className="phone-code phone-full">
                     <PhoneInput
                            readonly
                            name="phone_code"
                            country={"us"}
                            value={this.state.phone_code}
                            onChange={(phone) =>
                              this.setState({
                                phone_code:phone
                              })
                              
                            }
                            
                          />
                         
                    <Input
                      {...mobile_no}
                      name="mobile_no"
                      value={this.state.mobile_no}
                      onChange={this.handleChange}
                      placeholder="Mobile Number"
                      className={this.state.err_mobile !="" !="" ? "is-invalid" : ""}
                    />
                    {this.state.err_mobile !="" ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_mobile}
                    </Alert>:""}
                  </FormGroup>
                )}
                {!this.isForgetPassword && !this.isSTATE_NEWPASSWORD && (
                  <FormGroup>
                    <Input
                      {...passwordInputProps}
                      name="password"
                      value={this.state.password}
                      required
                      onChange={this.handleChange}
                      className={this.state.err_password !="" !="" ? "is-invalid" : ""}
                    />
                    
                     {this.state.err_password !="" ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_password}
                    </Alert>:""}
                    {this.isSignup && (
                      <div class="err-msg"
                        style={{
                          fontSize: "8px",
                          color: "#1170c3",
                          position: "relative",
                          top: "7px",
                          fontWeight:"bold"
                        }}
                      >
                        Password should be between 8 to 20 characters and should
                        contain minimum of 1 number, 1 character & 1 special character.
                      </div>
                    )}
                  </FormGroup>
                )}

                {this.isSTATE_NEWPASSWORD && this.state.token_exp == 1 ? (
                  <FormGroup>
                    <Input
                      {...passwordInputProps}
                      name="password"
                      value={this.state.password}
                      required
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                ) : (
                  ""
                )}

                {this.isSignup && (
                  <FormGroup>
                    <Input
                      {...confirmPasswordInputProps}
                      name="confrim"
                      value={this.state.confrim}
                      required
                      onChange={this.handleChange}
                      className={this.state.err_crm_password !="" !="" ? "is-invalid" : ""}
                    />
                    {this.state.err_crm_password !="" ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_crm_password}
                    </Alert>:""}
                  </FormGroup>
                )}
                {/* {this.isSignup && (
                  <FormGroup>
                    <Input
                      {...referelInputProps}
                      required
                      name="referralCode"
                      value={this.state.referralCode}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                )} */}
                {this.isSTATE_NEWPASSWORD && this.state.token_exp == 1 ? (
                  <FormGroup>
                    <Input
                      {...confirmPasswordInputProps}
                      name="confrim"
                      value={this.state.confrim}
                      required
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                ) : (
                  ""
                )}
              </div>
            </div>

            <Alert color={this.state.alertcolor} style={this.state.alertstyle}>
              {this.state.alertmsg}
            </Alert>
            {this.isSignup && (
              <FormGroup check className="check-terms">
                <Input
                  type="checkbox"
                  checked={this.state.isChecked}
                  onChange={this.checkChange}
                  required
                />
                <Link to="/terms" check>
                  {" "}
                  Agree to Terms and Conditions
                </Link>
                {this.state.err_isCheck !="" ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_isCheck}
                    </Alert>:""}
              </FormGroup>
            )}
            {this.isSignup && (
              <ReCAPTCHA
                required
                className="g-captcha"
                sitekey="6Ld1eTIaAAAAAAdLl25R9-NQaXUVpHwtT1go7-2Q"
                onChange={this.onClickRecaptcha}
              />
            )}
            {console.log(this.state.err_captcha.length)}
            {this.state.captcha == 0 ? <Alert style={{color:"#1170c3"}} >
                      {this.state.err_captcha}
                    </Alert>:""}
            {this.state.token_exp == 1 ? (
              <Button
                size="lg"
                type="submit"
                className="bg-gradient-theme-left border-0"
                block
                onClick={this.handleSubmit}
                disabled={!this.state}
              >
                {this.renderButtonText()}
              </Button>
            ) : (
              <div className="f-p">
                <Link to={"/forgot"} style={{ color: "white" }}>
                  Forgot Password?
                </Link>
              </div>
            )}
            {this.isLogin && (
              <div className="f-p">
                <Link to={"/forgot"}>Forgot Password?</Link>
              </div>
            )}
          </div>
          <NotificationSystem ref={this.notificationSystem} />
          {children}
        </Form>
        <div className="modal-popup donor-name">
          <Modal name="popup" show={this.state.popup}>
            <div className="data-innerr">
              <div className="signup-head">
                Successfully registered.
                <br />
                Please check your email to verify your account.
              </div>
              <Link to={"/login"} className="btn btn-primary">
                Proceed to Login
              </Link>
            </div>
          </Modal>
        </div>
        <div className="modal-popup donor-name">
          <Modal name="popup" show={this.state.popup2}>
            <div className="data-innerr">
              <div className="signup-head">
                Password sent on your registered Email.
              </div>
              <Link to={"/login"} className="btn btn-primary">
                Proceed to Login
              </Link>
            </div>
          </Modal>
        </div>
        <div className="modal-popup donor-name">
          <Modal name="popup" show={this.state.popup3}>
            <div className="data-innerr">
              <div className="signup-head">
                Password Succesfully Changed.
              </div>
              <Link to={"/login"} className="btn btn-primary">
                Proceed to Login
              </Link>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export const STATE_LOGIN = "LOGIN";
export const STATE_SIGNUP = "SIGNUP";
export const STATE_FORGET = "FORGET";
export const STATE_NEWPASSWORD = "NEWPASSWORD";

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  mobile_no: PropTypes.string,
  fullnameInputProps: PropTypes.object,
  roleInputProps: PropTypes.object,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  referelLabel: PropTypes.string,
  referelInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: "LOGIN",
  showLogo: true,
  fullnameInputProps: {
    type: "fullName",
    placeholder: "Full Name *",
  },
  usernameLabel: "Email",
  usernameInputProps: {
    type: "email",
    placeholder: "Email Id *",
  },
  passwordLabel: "Password",
  passwordInputProps: {
    type: "password",
    placeholder: "Password *",
  },
  confirmPasswordLabel: "Confirm Password",
  confirmPasswordInputProps: {
    type: "password",
    placeholder: "Confirm Password *",
  },
  referelLabel: "Referral Code",
  referelInputProps: {
    type: "referel",
    placeholder: "Referral Code (Optional)",
  },
  roleInputProps: {
    type: "role",
    placeholder: "Role",
  },

  onLogoClick: () => {},
};

export default AuthForm;
