import {  GoogleMapsAPI } from './client-config';

export let google;

export function googleMapLoader() {
  const url = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&callback=initMap&libraries=&v=weekly`;

  return new Promise((res, rej) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    window.initMap = () => {
      google = window.google;
      res();
    };

    script.onerror = err => {
      rej(err);
    };

    document.body.appendChild(script);
  });
}
