import React, { useRef, useState } from "react";
import Page from "components/Page";
import envirment from "./base_url";
import Modal from "pages/Modal";
import "../assets/css/customstyle.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const notificationSystem = React.createRef();
  const addNotification = (e) => {};

  const initialState = {
    name: "",
    email: "",
    issue: "",
    description: "",
    updateProfile: false,
    error: "",
    captcha: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setName":
        return { ...state, name: action.payload };
      case "setEmail":
        return { ...state, email: action.payload };
      case "setIssue":
        return { ...state, issue: action.payload };
      case "setDescription":
        return { ...state, description: action.payload };
      case "setUpdateProfile":
        return { ...state, updateProfile: action.payload };
      case "setError":
        return { ...state, error: action.payload };
      case "setCaptcha":
        return { ...state, captcha: action.payload };
    }
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "name":
        dispatch({ type: "setName", payload: e.target.value });
        break;
      case "email":
        dispatch({ type: "setEmail", payload: e.target.value });
        break;
      case "issue":
        dispatch({ type: "setIssue", payload: e.target.value });
        break;
      case "description":
        dispatch({ type: "setDescription", payload: e.target.value });
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let err = {};
    if (state.name.length === 0) {
      err = { ...err, name: "Name is Mandatory" };
    } else if (
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        state.email
      ) === false
    ) {
      err = { ...err, email: "Email is Mandatory" };
    } else if (state.issue.length === 0) {
      err = { ...err, issue: "Subject is Mandatory" };
    } else if (state.description.length === 0) {
      err = { ...err, issue: "Description is Mandatory" };
    } else if (state.captcha == "") {
      err = { ...err, captcha: "Please verify you are not a Robot" };
    } else {
      err = null;
    }

    if (err) {
      dispatch({ type: "setError", payload: { ...err } });
      console.log(err);
      return;
    } else {
      dispatch({ type: "setError", payload: { ...err } });
    }

    console.log("STATE DATA CHECK", state);

    const contactUsData = {
      name: state.name,
      email: state.email,
      issue: state.issue,
      description: state.description,
    };

    console.log("DATAAAAAAAAA", contactUsData);

    fetch(envirment.proxyUrl + envirment.baseUrl + "contact.php", {
      method: "POST",
      body: JSON.stringify(contactUsData),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "text/plain",
      },
    })
      .then((res) => {
        if (res.ok) {
          console.log("Request", res);
          return res.json();
        }
      })
      .then((res) => {
        dispatch({ type: "setUpdateProfile", payload: true });
      });
    dispatch({ type: "setName", payload: "" });
    dispatch({ type: "setEmail", payload: "" });
    dispatch({ type: "setIssue", payload: "" });
    dispatch({ type: "setDescription", payload: "" });
  };

  const onClickRecaptcha = (value) => {
    if (value == null) {
      console.log("Check Value", value);
    } else {
      dispatch({
        type: "setCaptcha",
        payload: value,
      });
    }
  };

  return (
    <Page>
      <Container>
        <div className="food-page contact-page">
          <h2 className="card-title">Contact Us</h2>
          <Card className="form-profile">
            <CardBody>
              <Form onSubmit={handleSubmit} className="fild-name">
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="name"
                      name="name"
                      placeholder="Name"
                      value={state.name}
                      onChange={handleChange}
                    />
                    <invalid-feedback>{state.error.name}</invalid-feedback>
                  </Col>
                  
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={state.email}
                      onChange={handleChange}
                    />
                    <invalid-feedback>{state.error.email}</invalid-feedback>
                  </Col>
                  
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      type="text"
                      name="issue"
                      placeholder="Subject"
                      value={state.issue}
                      onChange={handleChange}
                    />
                    <invalid-feedback>{state.error.issue}</invalid-feedback>
                  </Col>
                  
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Description"
                      value={state.description}
                      onChange={handleChange}
                    />
                    <invalid-feedback>{state.error.description}</invalid-feedback>
                  </Col>
                  
                </FormGroup>
                <FormGroup className="robot-error">
                  <ReCAPTCHA
                    required
                    className="g-captcha"
                    sitekey="6Ld1eTIaAAAAAAdLl25R9-NQaXUVpHwtT1go7-2Q"
                    onChange={onClickRecaptcha}
                  />
                  <invalid-feedback>{state.error.captcha}</invalid-feedback>
                </FormGroup>
                <FormGroup row className="submit-btn">
                  <Col sm={12}>
                    <Button style={{ width: "100%" }} onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
      <div className="modal-popup donor-name">
        <Modal show={state.updateProfile}>
          <div className="data-inner">
            <div className="signup-head">Request Submitted Succesfully</div>
            <Link to={"/dashboard"} className="btn btn-primary">
              Ok
            </Link>
          </div>
        </Modal>
      </div>
    </Page>
  );
};

export default ContactUs;
