import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const SingleRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            localStorage.getItem('user_id') != null   ? (
                console.log(localStorage.getItem('user_id')),
                <Component {...props} />
            ) :
                <Redirect to="/" /> 
        )}
    />
);

export default SingleRoute;