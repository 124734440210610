import { Content } from "components/Layout";
import React from "react";

const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app bg-light login_sign_page" {...restProps}>
    <Content fluid>{children}</Content>
  </main>
);

export default EmptyLayout;
