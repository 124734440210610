import {
  STATE_LOGIN,
  STATE_SIGNUP,
  STATE_FORGET,
  STATE_NEWPASSWORD,
} from "components/AuthForm";
import GAListener from "components/GAListener";
import { EmptyLayout, LayoutRoute, MainLayout } from "components/Layout";
import PageSpinner from "components/PageSpinner";
import AuthPage from "pages/AuthPage";
import VerifyAccount from "pages/VerifyAccount";
import MobileVerification from "pages/MobileVerification";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./styles/reduction.scss";
import ProtectedRecipeientRoute from "./components/ProtectedRecipeientRoutes";
import ProtectedAdminRoute from "./components/ProtectedAdminRoutes";
import ProtectedDonorRoute from "./components/ProtectedDonorRoutes";
import ProtectedVolunteerRoute from "./components/ProtectedVolunteerRoutes";
import ProtectedChapterLeadRoute from "./components/ProtectedChapeterLeadRoutes";
import ProtectedRoute from "./components/ProtectedRoutes";
import DonateFood from "./pages/DonateFood";
import SingleRoute from "./components/SingleRoutes";
import LoginRoute from "./components/ProtectedLoginRoutes";
import AdminProfile from "./pages/AdminProfile";
import AllRecipientCL from "./pages/AllRecipientCL";
import RecordPage from "./pages/PastRecords";
import Analytics from "./pages/Analytics";
import Terms from "./pages/Terms";
import LandingPage from "./pages/LandingPage";
import DonateMoneyVol from "./pages/DonateMoneyVol";
import ContactUs from "./pages/ContactUs";
import Queries from "./pages/queries";
import BulkUpload from "./pages/BulkUpload";

const AlertPage = React.lazy(() => import("pages/AlertPage"));
const AuthModalPage = React.lazy(() => import("pages/AuthModalPage"));
const BadgePage = React.lazy(() => import("pages/BadgePage"));
const ButtonGroupPage = React.lazy(() => import("pages/ButtonGroupPage"));
const ButtonPage = React.lazy(() => import("pages/ButtonPage"));
const CardPage = React.lazy(() => import("pages/CardPage"));
const ChartPage = React.lazy(() => import("pages/ChartPage"));
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const HomeDashboardPage = React.lazy(() => import("pages/HomeDashboard"));
const DropdownPage = React.lazy(() => import("pages/DropdownPage"));
const FormPage = React.lazy(() => import("pages/FormPage"));
const InputGroupPage = React.lazy(() => import("pages/InputGroupPage"));
const ModalPage = React.lazy(() => import("pages/ModalPage"));
const ProgressPage = React.lazy(() => import("pages/ProgressPage"));
const TablePage = React.lazy(() => import("pages/TablePage"));
const TypographyPage = React.lazy(() => import("pages/TypographyPage"));
const WidgetPage = React.lazy(() => import("pages/WidgetPage"));
const Profile = React.lazy(() => import("pages/Profile"));
const Food = React.lazy(() => import("pages/Food"));
// const Bash = React.lazy(() => import('pages/Bash'));
const FoodList = React.lazy(() => import("pages/FoodInfo"));
const FoodRequest = React.lazy(() => import("pages/DonateFood"));
const ReceipentFoodList = React.lazy(() => import("pages/ReceipentFoodList"));
const RealtimeMap = React.lazy(() => import("pages/realtime-map"));
const Privacy = React.lazy(() => import("pages/Privacy"));
const InProcessRequest = React.lazy(() => import("pages/Inprocessrequest"));
const GetLocation = React.lazy(() => import("pages/get-location"));
const Chat = React.lazy(() => import("pages/Chat"));
const AllVolunteers = React.lazy(() => import("pages/AllVounteers"));
const AllDonor = React.lazy(() => import("pages/AllDonor"));
const AllReceipent = React.lazy(() => import("pages/AllReceipent"));
const DonateFood1 = React.lazy(() => import("pages/DonateFood1"));
const CheapterLead = React.lazy(() => import("pages/CheapterLead"));
const ChatReceipient = React.lazy(() => import("pages/ChatReceipient"));
const LandingTemp = React.lazy(() => import("pages/LandingPage_temp"));

// edit by sunil
const VolunteerActive = React.lazy(() => import("pages/VolunteerActive"));
const VolunteerActiveRecipient = React.lazy(() =>
  import("pages/VolunteerActiveRecipient")
);
const Tracking = React.lazy(() => import("components/Map/Tracking"));
//end sunil

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {
  renderHelloWorld = () => {
    window.localStorage.clear(); //clear all localstorage
    // window.localStorage.removeItems(); //remove one item
    window.location = "/";
  };
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={(props) => <LandingPage {...props} />}
            />
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />

            <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />
            <LayoutRoute
              exact
              path="/signup/:token"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />
            <LayoutRoute
              exact
              path="/register"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />

            <LayoutRoute
              exact
              path="/forgot"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_FORGET} />
              )}
            />

            <LayoutRoute
              exact
              path="/resetpassword/:token"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_NEWPASSWORD} />
              )}
            />
            <LayoutRoute
              exact
              path="/verify/:token"
              layout={EmptyLayout}
              component={(props) => <VerifyAccount {...props} />}
            />
            <LayoutRoute
              exact
              path="/mobile"
              layout={EmptyLayout}
              component={(props) => <MobileVerification {...props} />}
            />
            <Route exact path="/terms" component={Terms} />
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/signout" render={this.renderHelloWorld} />
                <SingleRoute exact path="/profile" component={Profile} />
                <Route exact path="/landingtemp" component={LandingTemp} />
                <ProtectedDonorRoute exact path="/food" component={Food} />
                <Route exact path="/records" component={RecordPage} />
                <ProtectedRoute exact path="/foodlist" component={FoodList} />
                <ProtectedDonorRoute exact path="/food/:id" component={Food} />
                {/* <ProtectedRecipeientRoute exact path='/donorrequest' component={ReceipentFoodList}/> */}

                {/* <Route exact path="/realtime" component={RealtimeMap} /> */}
                <SingleRoute exact path="/privacy" component={Privacy} />
                <Route exact path="/chat/:id" component={Chat} />
                <Route exact path="/chatrec/:id" component={ChatReceipient} />
                <ProtectedAdminRoute
                  exact
                  path="/cheapterlead"
                  component={CheapterLead}
                />
                <ProtectedAdminRoute
                  exact
                  path="/analytics"
                  component={Analytics}
                />
                <ProtectedAdminRoute
                  exact
                  path="/queries"
                  component={Queries}
                />
                <ProtectedAdminRoute
                  exact
                  path="/bulkupload"
                  component={BulkUpload}
                />
                <ProtectedRecipeientRoute
                  exact
                  path="/inprocess"
                  component={InProcessRequest}
                />

                <ProtectedAdminRoute
                  exact
                  path="/allvolunteers"
                  component={AllVolunteers}
                />
                <ProtectedAdminRoute
                  exact
                  path="/alldonor"
                  component={AllDonor}
                />
                <ProtectedAdminRoute
                  exact
                  path="/allrecepient"
                  component={AllReceipent}
                />
                <ProtectedAdminRoute
                  exact
                  path="/adminprofile"
                  component={AdminProfile}
                />
                <Route
                  exact
                  path="/allrecipientcl"
                  component={AllRecipientCL}
                />

                {/*edit by sunil*/}
                <ProtectedVolunteerRoute
                  exact
                  path="/foodrequest/:food_id/:from/:to/:name"
                  component={DonateFood}
                />
                <ProtectedVolunteerRoute
                  exact
                  path="/donatevol"
                  component={DonateMoneyVol}
                />
                {/* <ProtectedVolunteerRoute
                  exact
                  path="/foodrequest/:food_id/:from/:to"
                  component={DonateFood1}
                /> */}
                <ProtectedVolunteerRoute
                  exact
                  path="/onair"
                  component={VolunteerActiveRecipient}
                />
                <ProtectedDonorRoute
                  exact
                  path="/tracking/:food_id/:vol_id"
                  component={Tracking}
                />
                {/* <ProtectedVolunteerRoute exact path="/tracking/:food_id" component={Tracking} /> */}
                <ProtectedVolunteerRoute
                  exact
                  path="/tracking/:food_id"
                  component={DonateFood1}
                />
                {/*end sunil*/}

                {/* <Route exact path="/bash" component={Bash} /> */}
                <ProtectedRoute
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                />
                <ProtectedRoute exact path="/contactus" component={ContactUs} />
                <ProtectedRoute
                  exact
                  path="/HomeDashboard"
                  component={HomeDashboardPage}
                />
                <Route exact path="/login-modal" component={AuthModalPage} />
                <Route exact path="/buttons" component={ButtonPage} />
                <Route exact path="/cards" component={CardPage} />
                <Route exact path="/widgets" component={WidgetPage} />
                <Route exact path="/typography" component={TypographyPage} />
                <Route exact path="/alerts" component={AlertPage} />
                <Route exact path="/tables" component={TablePage} />
                <Route exact path="/badges" component={BadgePage} />
                <Route
                  exact
                  path="/button-groups"
                  component={ButtonGroupPage}
                />
                <Route exact path="/dropdowns" component={DropdownPage} />
                <Route exact path="/progress" component={ProgressPage} />
                <Route exact path="/modals" component={ModalPage} />
                <Route exact path="/forms" component={FormPage} />
                <Route exact path="/input-groups" component={InputGroupPage} />
                <Route exact path="/charts" component={ChartPage} />
                <Route exact path="/getlocation" component={GetLocation} />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
