import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            localStorage.getItem('user_id') !=null ? 
            localStorage.getItem('user_role_id') == '1' || localStorage.getItem('user_role_id') == '2' || localStorage.getItem('user_role_id') == '3' || localStorage.getItem('user_role_id') == '4'? (
                <Component {...props} />
                ) :
                <Redirect to="/dashboard" />  :
            <Redirect to="/" /> 

            
        )}
    />
);

export default ProtectedRoute;