import React from 'react';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import envirment from './base_url';

export default class VerifyAccount extends React.Component {
  constructor(props) {
    console.log(props.match.params.token)
    super(props);
    this.state = {
      token: props.match.params.token,
      success: false,
      failed: false,
    };
    fetch(envirment.proxyUrl + envirment.baseUrl + 'verify_account.php', {
      method: 'POST',
      body: JSON.stringify({ token: this.state.token }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        console.log('Check', res.success);
        if(res.success == 1 ){
          this.setState({
            success: true,        
          })  
        }else if(res.success == 0){
          this.setState({
            failure: true,        
          });  
        }
      });
  }

  render() {
    return (
      <div>
        <div className="modal-popup donor-name">
          <Modal show={this.state.success}>
            <div className="data-inner">
              <div className="signup-head">
                Account Successfully Verified.
              </div>
              <Link to={'/'} className="btn btn-primary">
              Proceed to Login
              </Link>
            </div>
          </Modal>
        </div>
        <div className="modal-popup donor-name">
          <Modal show={this.state.failure}>
            <div className="data-inner">
              <div className="signup-head">
              This email has already been verified.
              </div>
              <Link to={'/'} className="btn btn-primary">
                Login
              </Link>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
