import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Polyline,
} from "react-google-maps";
import { Input } from "reactstrap";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "./client-config";
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

class SingleSelectMap extends Component {
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    if (
      this.props.location.mapPosition.lat &&
      this.props.location.mapPosition.lng
    ) {
      Geocode.fromLatLng(
        this.props.location.mapPosition.lat,
        this.props.location.mapPosition.lng
      ).then(
        (response) => {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray);

          // console.log('city', city, area, state);

          this.props.dispatch({
            type: "setLocation",
            payload: {
              address: address ? address : "",
              area: area ? area : "",
              city: city ? city : "",
              state: state ? state : "",
              mapPosition: {
                lat: this.props.location.mapPosition.lat,
                lng: this.props.location.mapPosition.lng,
              },
              markerPosition: {
                lat: this.props.location.markerPosition.lat,
                lng: this.props.location.markerPosition.lat,
              },
            },
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onInfoWindowClose = (event) => {};

  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.

    const newLocation = {
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    };

    this.props.dispatch({
      type: "setLocation",
      payload: newLocation,
    });
    this.props.dispatch({
      type: "setPlaces",
      payload: [newLocation],
    });
    if (this.props.places.length >= 1) {
      this.props.dispatch({ type: "setZoom", payload: 8 });
    }
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.props.location.mapPosition.lat,
            lng: this.props.location.mapPosition.lng,
          }}
        >
          {/* InfoWindow on top of marker */}

          {/*Marker*/}

          {this.props.places.map((item, index) => (
            <>
              <Marker
                key={index}
                google={props.google}
                name={"Dolores park"}
                position={{
                  lat: item.markerPosition.lat,
                  lng: item.markerPosition.lng,
                }}
              />
              <InfoWindow
                onClose={this.onInfoWindowClose}
                position={{
                  lat: item.markerPosition.lat + 0.0018,
                  lng: item.markerPosition.lng,
                }}
              >
                <div>
                  <span style={{ padding: 0, margin: 0 }}>{item.address}</span>
                </div>
              </InfoWindow>
            </>
          ))}

          {/* For Auto complete Search Box */}
          <Autocomplete
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "16px",
              marginTop: "2px",
              marginBottom: "500px",
            }}
            onPlaceSelected={this.onPlaceSelected}
            types={["(regions)"]}
          />
        </GoogleMap>
      ))
    );
    let map;
    if (
      this.props.location.mapPosition.lat &&
      this.props.location.mapPosition.lng
    ) {
      map = (
        <div className="location-add">
          <div className="form-group">
            <Input
              type="text"
              name="address"
              className="form-control"
              readOnly="readOnly"
              value={this.props.location.address}
            />
          </div>

          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default SingleSelectMap;
