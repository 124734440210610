import React from "react";
import { googleMapLoader, google } from "./googleMapLoader";

export default class GoogleMap extends React.Component {
  state = {
    marker: { lat: 29.5825606, lng: 74.3136261 },
    map: "",
  };

  googleMapRef = React.createRef();

  componentDidMount() {
    googleMapLoader().then(() => {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const map = new google.maps.Map(this.googleMapRef.current, {
        zoom: 7,
        center: { lat: 41.85, lng: -87.65 },
      });
      directionsRenderer.setMap(map);
      this.setState({ map });

      this.calculateAndDisplayRoute(directionsService, directionsRenderer);
    });
  }

  componentWillUnmount() {
    if (window.google) {
      for (let i = 0; i < document.scripts.length; i++) {
        if (document.scripts[i].src.match(/maps.googleapis.com/)) {
          document.scripts[i].parentElement.removeChild(document.scripts[i]);
        }
      }
    }
  }

  calculateAndDisplayRoute(directionsService, directionsRenderer) {
    directionsService.route(
      {
        origin: new google.maps.LatLng(this.props.places.from),
        destination: new google.maps.LatLng(this.props.places.to),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          const {
            distance,
            duration,
            end_location,
            start_location,
          } = response.routes[0].legs[0];
          console.log("nnnnnnnnnnnnnnnnn",distance);
          this.props.handleChange("distance", distance.text);
          this.props.handleChange("duration", duration.text);

          const markerOne = this.makeMarker(start_location, "volunteer");
          const markerTwo = this.makeMarker(end_location, "donor");

          this.makeInfoBox(start_location, "volunteer", markerOne);
          this.makeInfoBox(end_location, "donor", markerTwo);

          directionsRenderer.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  makeInfoBox(position, content, marker) {
    const infoBox = new google.maps.InfoWindow({
      content: content,
      position: position,
    });
    infoBox.open(this.state.map, marker);
  }

  makeMarker(position, title) {
    new google.maps.Marker({
      position: position,
      map: this.state.map,
    });
  }

  render() {
    return (
      <div ref={this.googleMapRef} style={{ height: this.props.height }}></div>
    );
  }
}

//old
// import React from 'react';
// import { googleMapLoader, google } from './googleMapLoader';

// export default class GoogleMap extends React.Component {
//   state = {
//     marker: { lat: 29.5825606, lng: 74.3136261 },
//     map: '',
//     point: [],
//   };

//   googleMapRef = React.createRef();

//   componentDidMount() {
//     // setInterval(() => {
//     //   this.setState({
//     //     marker: {
//     //       lat: this.state.marker.lat,
//     //       lng: this.state.marker.lng - 0.0015,
//     //     },
//     //   });
//     // }, 1000);

//     googleMapLoader().then(() => {
//       const directionsService = new google.maps.DirectionsService();
//       const directionsRenderer = new google.maps.DirectionsRenderer();
//       const map = new google.maps.Map(this.googleMapRef.current, {
//         zoom: 7,
//         center: { lat: 41.85, lng: -87.65 },
//       });
//       directionsRenderer.setMap(map);
//       this.setState({ map });

//       this.calculateAndDisplayRoute(directionsService, directionsRenderer);
//     });
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (this.state.map !== '') {
//       if (this.state.marker.lng !== prevState.marker.lng) {
//         this.state.point.map(i => i.setMap(null));

//         const marker = new google.maps.Marker({
//           position: {
//             lat: this.state.marker.lat,
//             lng: this.state.marker.lng,
//           },
//           map: this.state.map,
//         });
//         marker.setMap(this.state.map);
//         this.setState({ point: [...this.state.point, marker] });
//       }
//     }
//   }

//   calculateAndDisplayRoute(directionsService, directionsRenderer) {
//     directionsService.route(
//       {
//         origin: new google.maps.LatLng({ lat: 29.5825606, lng: 74.3136261 }),
//         destination: new google.maps.LatLng({
//           lat: 29.9091728,
//           lng: 73.8439466,
//         }),
//         travelMode: google.maps.TravelMode.DRIVING,
//       },
//       (response, status) => {
//         if (status === 'OK') {
//           directionsRenderer.setDirections(response);
//         } else {
//           window.alert('Directions request failed due to ' + status);
//         }
//       },
//     );
//   }

//   render() {
//     return <div ref={this.googleMapRef} style={{ height:400 }}></div>;
//   }
// }

// // import React, { Component } from 'react';
// // import {
// //     withGoogleMap,
// //     GoogleMap,
// //     withScriptjs,
// //     Marker,
// //     Polyline,
// // } from 'react-google-maps';
// // import Geocode from 'react-geocode';
// // import { GoogleMapsAPI } from './client-config';
// // Geocode.setApiKey(GoogleMapsAPI);
// // Geocode.enableDebug();

// // export default class DonateFoodMap extends Component {

// //     onInfoWindowClose = event => {};

// //     render() {
// //             const AsyncMap = withScriptjs(
// //                     withGoogleMap(props => (
// //                             <GoogleMap
// //           google={props.google}
// //           defaultZoom={this.props.zoom}
// //           defaultCenter={{
// //             lat: this.props.places.to.lat,
// //             lng: this.props.places.to.lng,
// //           }}
// //         >
// //         <div>

// //                 <Marker
// //                   google={props.google}
// //                   name={'Dolores park'}
// //                   position={{
// //                     lat: this.props.places.from.lat,
// //                     lng: this.props.places.from.lng,
// //                   }}
// //                 />

// //                 <Marker
// //                   google={props.google}
// //                   name={'Dolores park'}
// //                   position={{
// //                     lat: this.props.places.to.lat,
// //                     lng: this.props.places.to.lng,
// //                   }}
// //                 />

// //           <Polyline path={[this.props.places.from,this.props.places.to]} />

// // </div> </GoogleMap>
// // )),
// // );
// // let map;
// // if ("from" in this.props.places && "to" in this.props.places) {
// //     map = (
// //             <div>
// //               <AsyncMap
// //                 googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
// //                 loadingElement={<div style={{ height: `100%` }} />}
// //                 containerElement={<div style={{ height: this.props.height }} />}
// //                 mapElement={<div style={{ height: `100%` }} />}
// //               />
// //             </div>
// //             );
// //         } else {
// //             map = <div style={{ height: this.props.height }} />;
// //         }
// //         return map;
// //     }
// // }
