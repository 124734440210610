import React from 'react'
import { Redirect, Route } from 'react-router-dom'


const ProtectedLoginRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            localStorage.getItem('user_id') !==null ? 
                <Component {...props} />
                 :
                    <Redirect to="/dashboard" /> 

            
        )}
    />
);

export default ProtectedLoginRoute;